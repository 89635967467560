import React from 'react';

export enum ButtonVariantTypes {
  Primary,
  Secondary,
  Tertiary,
}

export enum ButtonThemeTypes {
  Dark,
  Light,
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariantTypes;
  theme?: ButtonThemeTypes;
  iconPre?: React.ReactNode;
  iconPost?: React.ReactNode;
}
