/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import {
  ArrowContainer,
  CarouselContainer,
  CarouselIndicator,
  CarouselIndicatorContainer,
  GridContainer,
} from './styles';
import {
  CarouselArrowProps,
  CarouselIndicatorProps,
  CarouselProps,
} from './types';
import { breakpoints } from '../../../breakpoints';
import { ClassNames } from '@emotion/react';

export const Arrow = ({
  shouldShowArrow,
  onArrowClick,
  arrowIcon,
}: CarouselArrowProps) => {
  return (
    <div css={ArrowContainer(shouldShowArrow)}>
      <span onClick={onArrowClick}>{shouldShowArrow ? arrowIcon : ''}</span>
    </div>
  );
};

export const CarouselIndicators = ({
  shouldShowCarouselIndicators,
  data,
  itemsPerPage,
  startIdx,
  setStartIdx,
}: CarouselIndicatorProps) => {
  const handleIndicatorClick = function (isActive: boolean, currIdx: number) {
    return function () {
      if (isActive) return;
      setStartIdx(itemsPerPage * currIdx);
    };
  };

  return (
    <>
      {shouldShowCarouselIndicators && (
        <div css={CarouselIndicatorContainer}>
          {Array.from({
            length: Math.ceil(data.length / itemsPerPage),
          }).map((_, i) => {
            return (
              <span
                key={i}
                css={CarouselIndicator(startIdx / itemsPerPage === i)}
                onClick={handleIndicatorClick(startIdx / itemsPerPage === i, i)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export const Carousel = ({
  children,
  loader,
  data,
  itemsPerPage,
  isDataLoading,
  itemsGridContainerClassName,
  shouldScrollToContent = true,
}: CarouselProps) => {
  const [startIdx, setStartIdx] = useState(0);
  const dataRef = useRef<HTMLDivElement>(null);
  const isLargeScreen = useMediaQuery(`(min-width: ${breakpoints[2]}px)`);

  useEffect(() => {
    setStartIdx(0);
  }, [data]);

  const memoisedData = useMemo(
    () =>
      isLargeScreen ? data?.slice(startIdx, startIdx + itemsPerPage) : data,
    [isLargeScreen, itemsPerPage, startIdx, data]
  );

  useEffect(() => {
    !isDataLoading &&
      dataRef?.current?.offsetTop &&
      shouldScrollToContent &&
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: dataRef?.current?.offsetTop - 80,
      });
  }, [startIdx]);

  const handleLeftArrowClick = function () {
    startIdx >= itemsPerPage &&
      setStartIdx((currentIdx) => currentIdx - itemsPerPage);
  };

  const handleRightArrowClick = function () {
    startIdx + itemsPerPage < data.length &&
      setStartIdx((currentIdx) => currentIdx + itemsPerPage);
  };

  return (
    <ClassNames>
      {({ css, cx }) => (
        <div css={CarouselContainer} ref={dataRef}>
          <Arrow
            {...{
              shouldShowArrow: startIdx >= itemsPerPage && data.length > 0,
              onArrowClick: handleLeftArrowClick,
              arrowIcon: '<',
            }}
          />

          <div
            className={cx(
              css`
                ${GridContainer}
              `,
              itemsGridContainerClassName
            )}
          >
            {isDataLoading ? (
              loader
            ) : (
              <>
                {children(memoisedData)}
                <CarouselIndicators
                  {...{
                    shouldShowCarouselIndicators:
                      itemsPerPage < data.length && data.length > 0,
                    data,
                    itemsPerPage,
                    startIdx,
                    setStartIdx,
                    dataRef,
                  }}
                />
              </>
            )}
          </div>

          <Arrow
            {...{
              shouldShowArrow:
                startIdx + itemsPerPage < data.length && data.length > 0,
              onArrowClick: handleRightArrowClick,
              arrowIcon: '>',
            }}
          />
        </div>
      )}
    </ClassNames>
  );
};
