import React from 'react';
import MaterialTooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MaterialTooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--yt-dark)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--yt-dark)',
  },
}));
