/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import Profile from './Profile';
import { AppConstants } from '../../../constants';
import { breakpoints } from '../../../breakpoints';
import { AppBarMenu } from '../Icons';
import { Sidebar } from '../Sidebar';
import { NavigationTestIds } from '../../../testIds';
import TermsAndConditionsModal from './TermsAndConditions/TermsAndConditionsModal';
import { CHECK_IF_USER_ACCEPTED_TERMS_AND_CONDITIONS } from '../../../middleware/queries';
import { YtAuthContext } from '../../../auth-context/context';
import {
  Navbar,
  Container,
  PoweredBy,
  // Vendor,
  AppNameContainer,
  AppMenu,
} from './styles';

const { navMenuToggle } = NavigationTestIds;

const { /*poweredBy, vendor,*/ insightsHubHeaderText } = AppConstants;

export const Navigation = () => {
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints[1]}px)`);

  const {
    userDetails: { username },
  } = useContext(YtAuthContext);

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [showTermsAndConditions, setShowTermsAndConditions] =
    useState<boolean>(false);

  useQuery(CHECK_IF_USER_ACCEPTED_TERMS_AND_CONDITIONS, {
    skip: !username,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        username,
      },
    },
    onCompleted: ({ checkIfUserAcceptedTerms }) => {
      !checkIfUserAcceptedTerms?.accepted && setShowTermsAndConditions(true);
    },
    onError: () => {
      setShowTermsAndConditions(true);
    },
  });

  const handleToggleSidebar = function (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event?.stopPropagation();
    setOpenSidebar((currentOpenSidebar) => !currentOpenSidebar);
  };

  const handleSidebarClose = function () {
    setOpenSidebar(false);
  };

  const handleCloseTermsAndConditions = function () {
    setShowTermsAndConditions(false);
  };

  return (
    <nav css={Navbar}>
      <ul css={Container}>
        <li css={PoweredBy}>
          {/* <span css={XtraSmallTextStyle}>{poweredBy}</span>
          <span>
            <img src="./fractal_logo.svg" alt="Fractal logo" />
          </span> */}
        </li>
        <li css={AppNameContainer}>
          {isSmallScreen && (
            <div
              css={AppMenu}
              onClick={handleToggleSidebar}
              data-testid={navMenuToggle}
            >
              <AppBarMenu />
            </div>
          )}
          <div>
            {insightsHubHeaderText}
            {/* &nbsp;<span css={Vendor}>{vendor}</span> */}
          </div>
          {isSmallScreen && <Profile />}
        </li>
        <li>{!isSmallScreen && <Profile />}</li>
        <Sidebar
          {...{
            open: openSidebar,
            onClose: handleSidebarClose,
          }}
        />
        <TermsAndConditionsModal
          {...{
            open: showTermsAndConditions,
            onClose: handleCloseTermsAndConditions,
          }}
        />
      </ul>
    </nav>
  );
};
