import React, { useEffect, useState } from 'react';
import { Tabs as ReactTabs, TabList, TabPanel, Tab } from 'react-tabs';
import { ClassNames } from '@emotion/react';
import { TabsProps } from './types';
import { MediumTextStyle } from '../../../typography';
import {
  TabListStyle,
  TabPanelStyle,
  TabStyle,
  TabsContainerStyle,
} from './styles';

export const Tabs = ({
  tabContents,
  tabHeadings,
  selectedTabIndex,
  onSelectTab,
}: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    selectedTabIndex !== null &&
      selectedTabIndex !== undefined &&
      setSelectedIndex(selectedTabIndex);
  }, [selectedTabIndex]);

  const handleSelect = function (index: number) {
    setSelectedIndex(index);
    onSelectTab?.(index);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <ReactTabs
          {...{
            className: css`
              ${TabsContainerStyle}
            `,
            selectedIndex,
            onSelect: handleSelect,
          }}
        >
          <TabList
            {...{
              className: css`
                ${TabListStyle};
                ${MediumTextStyle};
              `,
            }}
          >
            {tabHeadings.map((tabHeading, tabIdx) => (
              <Tab
                key={tabIdx}
                {...{
                  className: css`
                    ${TabStyle(selectedIndex === tabIdx)};
                  `,
                }}
              >
                {tabHeading}
              </Tab>
            ))}
          </TabList>

          {tabContents.map((tabContent, idx) => (
            <TabPanel
              key={idx}
              {...{
                className: css`
                  ${TabPanelStyle};
                `,
              }}
            >
              {tabContent}
            </TabPanel>
          ))}
        </ReactTabs>
      )}
    </ClassNames>
  );
};
