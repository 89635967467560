import React, { useContext } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  concat,
} from '@apollo/client';
import { routes } from './routes';
import { YtAuthContext } from './auth-context/context';

const router = createBrowserRouter(routes);

export default function App() {
  const { authAccessToken } = useContext(YtAuthContext);

  const httpLink = new HttpLink({
    uri: `${
      process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_MIDDLEWARE_URL
        : 'http://localhost:8080/'
    }graphql`,
    credentials: 'include',
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: authAccessToken || null,
      },
    }));

    return forward(operation);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: concat(authMiddleware, httpLink),
  });

  return (
    <ApolloProvider {...{ client }}>
      <RouterProvider {...{ router }} />
    </ApolloProvider>
  );
}
