import { css } from '@emotion/react';

export const TabsContainerStyle = css({
  width: '100%',
});

export const TabListStyle = css({
  display: 'flex',
  columnGap: 'var(--yt-size-m)',
  alignItems: 'center',
  listStyle: 'none',
  marginTop: 'var(--yt-size-9xl)',
  borderBottom: 'var(--yt-size-s) solid var(--yt-neutral-grey-3)',
});

export const TabStyle = (isSelected: boolean) => css`
  color: ${isSelected ? 'var(--yt-dark)' : 'var(--yt-neutral)'};
  cursor: pointer;
  padding: var(--yt-size-xl);
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const TabPanelStyle = css({
  marginTop: 'var(--yt-size-3xl)',
});
