/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AcceptTermsAndConditionsInput = {
  username: Scalars['String']['input'];
};

export type AcceptTermsAndConditionsOutput = {
  __typename?: 'AcceptTermsAndConditionsOutput';
  success: Scalars['Boolean']['output'];
};

export type ChangeUserPwdInput = {
  confirmNewPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type ChangeUserPwdOutput = {
  __typename?: 'ChangeUserPwdOutput';
  message: Scalars['String']['output'];
};

export type CheckIfUserAcceptedTermsInput = {
  username: Scalars['String']['input'];
};

export type CheckIfUserAcceptedTermsOutput = {
  __typename?: 'CheckIfUserAcceptedTermsOutput';
  accepted: Scalars['Boolean']['output'];
};

export type DislikeNewsInput = {
  newsOrTrendId?: InputMaybe<Scalars['String']['input']>;
};

export type DislikeNewsOutput = {
  __typename?: 'DislikeNewsOutput';
  _id: Scalars['String']['output'];
  dislikeCount: Scalars['Float']['output'];
};

export type EarningCallSummaryType = {
  __typename?: 'EarningCallSummaryType';
  authenticatedUrl: Scalars['String']['output'];
  author: Scalars['String']['output'];
  dislike?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  filepath?: Maybe<Scalars['String']['output']>;
  like?: Maybe<Scalars['Float']['output']>;
  publishedQuarter?: Maybe<Scalars['String']['output']>;
  publishedYear?: Maybe<Scalars['String']['output']>;
  scrapedAt?: Maybe<Scalars['String']['output']>;
  summary: Array<Maybe<SummaryType>>;
};

export type GetAccessTokenFromRefreshTokenOutput = {
  __typename?: 'GetAccessTokenFromRefreshTokenOutput';
  access: Scalars['String']['output'];
  user?: Maybe<UserData>;
};

export type GrowthMarketingInput = {
  currentDate: Scalars['String']['input'];
};

export type IndustryNewsAndTrendsInput = {
  currentDate: Scalars['String']['input'];
};

export type KeyHighlightType = {
  __typename?: 'KeyHighlightType';
  id: Scalars['String']['output'];
  keyHighlight: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LikeNewsInput = {
  newsOrTrendId?: InputMaybe<Scalars['String']['input']>;
};

export type LikeNewsOutput = {
  __typename?: 'LikeNewsOutput';
  _id: Scalars['String']['output'];
  likeCount: Scalars['Float']['output'];
};

export type LoginInput = {
  captchaValue: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  access: Scalars['String']['output'];
  user?: Maybe<UserData>;
};

export type LogoutOutput = {
  __typename?: 'LogoutOutput';
  message: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions?: Maybe<AcceptTermsAndConditionsOutput>;
  changeUserPwd?: Maybe<ChangeUserPwdOutput>;
  dislikeNews?: Maybe<DislikeNewsOutput>;
  getAccessTokenFromRefreshToken?: Maybe<GetAccessTokenFromRefreshTokenOutput>;
  likeNews?: Maybe<LikeNewsOutput>;
  loginUser?: Maybe<LoginOutput>;
  logoutUser?: Maybe<LogoutOutput>;
  passwordReset?: Maybe<PasswordResetOutput>;
  passwordResetConfirm?: Maybe<PasswordResetConfirmOutput>;
  registerUser?: Maybe<RegisterUserOutput>;
};


export type MutationAcceptTermsAndConditionsArgs = {
  input?: InputMaybe<AcceptTermsAndConditionsInput>;
};


export type MutationChangeUserPwdArgs = {
  input?: InputMaybe<ChangeUserPwdInput>;
};


export type MutationDislikeNewsArgs = {
  input?: InputMaybe<DislikeNewsInput>;
};


export type MutationLikeNewsArgs = {
  input?: InputMaybe<LikeNewsInput>;
};


export type MutationLoginUserArgs = {
  input?: InputMaybe<LoginInput>;
};


export type MutationPasswordResetArgs = {
  input?: InputMaybe<PasswordResetInput>;
};


export type MutationPasswordResetConfirmArgs = {
  input?: InputMaybe<PasswordResetConfirmInput>;
};


export type MutationRegisterUserArgs = {
  input?: InputMaybe<RegisterUserInput>;
};

export type NewsSourceType = {
  __typename?: 'NewsSourceType';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type NewsType = {
  __typename?: 'NewsType';
  _id?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dislike?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  like?: Maybe<Scalars['Float']['output']>;
  publishedAt?: Maybe<Scalars['String']['output']>;
  relevantContextImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  scrapedAt?: Maybe<Scalars['String']['output']>;
  scrapingTool?: Maybe<Scalars['String']['output']>;
  source?: Maybe<NewsSourceType>;
  summary?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlToImage?: Maybe<Scalars['String']['output']>;
};

export type PasswordResetConfirmInput = {
  confirmPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};

export type PasswordResetConfirmOutput = {
  __typename?: 'PasswordResetConfirmOutput';
  message: Scalars['String']['output'];
};

export type PasswordResetInput = {
  email: Scalars['String']['input'];
};

export type PasswordResetOutput = {
  __typename?: 'PasswordResetOutput';
  message: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  checkIfUserAcceptedTerms?: Maybe<CheckIfUserAcceptedTermsOutput>;
  earningCallSummaries?: Maybe<Array<Maybe<EarningCallSummaryType>>>;
  growthMarketingTrends?: Maybe<Array<Maybe<NewsType>>>;
  industryNewsAndTrends?: Maybe<Array<Maybe<NewsType>>>;
  sourceDirectory?: Maybe<Array<Maybe<SourceDirectoryType>>>;
  viewArticlesAnalysis?: Maybe<ViewArticlesAnalysisOutput>;
  viewEcsSummary?: Maybe<ViewEcsSummaryOutput>;
  viewLatestTrendsByCategory?: Maybe<Array<Maybe<ViewLatestTrendsByCategoryOutput>>>;
};


export type QueryCheckIfUserAcceptedTermsArgs = {
  input?: InputMaybe<CheckIfUserAcceptedTermsInput>;
};


export type QueryGrowthMarketingTrendsArgs = {
  input?: InputMaybe<GrowthMarketingInput>;
};


export type QueryIndustryNewsAndTrendsArgs = {
  input?: InputMaybe<IndustryNewsAndTrendsInput>;
};


export type QuerySourceDirectoryArgs = {
  input?: InputMaybe<SourceDirectoryInput>;
};


export type QueryViewArticlesAnalysisArgs = {
  input?: InputMaybe<ViewArticlesAnalysisInput>;
};


export type QueryViewEcsSummaryArgs = {
  input?: InputMaybe<ViewEcsSummaryInput>;
};

export type RegisterUserInput = {
  captchaValue: Scalars['String']['input'];
  confirmPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RegisterUserOutput = {
  __typename?: 'RegisterUserOutput';
  message: Scalars['String']['output'];
};

export type SourceDirectoryInput = {
  feature: Scalars['String']['input'];
};

export type SourceDirectoryType = {
  __typename?: 'SourceDirectoryType';
  categoryName: Scalars['String']['output'];
  sources: Array<Maybe<SourceType>>;
};

export type SourceType = {
  __typename?: 'SourceType';
  sourceName: Scalars['String']['output'];
  sourceUrl: Scalars['String']['output'];
};

export type SummaryType = {
  __typename?: 'SummaryType';
  entity?: Maybe<Scalars['String']['output']>;
  values: Array<Maybe<ValueType>>;
};

export type UserData = {
  __typename?: 'UserData';
  email: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type ValueType = {
  __typename?: 'ValueType';
  subEntity?: Maybe<Scalars['String']['output']>;
  subEntityValues: Array<Maybe<Scalars['String']['output']>>;
};

export type ViewArticlesAnalysisInput = {
  analysis?: InputMaybe<Scalars['Boolean']['input']>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type ViewArticlesAnalysisOutput = {
  __typename?: 'ViewArticlesAnalysisOutput';
  analysisXlsBase64Str?: Maybe<Scalars['String']['output']>;
};

export type ViewEcsSummaryInput = {
  gcsFilePath?: InputMaybe<Scalars['String']['input']>;
};

export type ViewEcsSummaryOutput = {
  __typename?: 'ViewEcsSummaryOutput';
  base64Str?: Maybe<Scalars['String']['output']>;
};

export type ViewLatestTrendsByCategoryOutput = {
  __typename?: 'ViewLatestTrendsByCategoryOutput';
  category: Scalars['String']['output'];
  categoryImg: Scalars['String']['output'];
  keyHighlights?: Maybe<Array<Maybe<KeyHighlightType>>>;
};

export type LikeNewsAndTrendsMutationVariables = Exact<{
  input?: InputMaybe<LikeNewsInput>;
}>;


export type LikeNewsAndTrendsMutation = { __typename?: 'Mutation', likeNews?: { __typename?: 'LikeNewsOutput', likeCount: number } | null };

export type DislikeNewsAndTrendsMutationVariables = Exact<{
  input?: InputMaybe<DislikeNewsInput>;
}>;


export type DislikeNewsAndTrendsMutation = { __typename?: 'Mutation', dislikeNews?: { __typename?: 'DislikeNewsOutput', dislikeCount: number, _id: string } | null };

export type LoginUserMutationVariables = Exact<{
  input?: InputMaybe<LoginInput>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'LoginOutput', access: string, user?: { __typename?: 'UserData', username: string, email: string } | null } | null };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser?: { __typename?: 'LogoutOutput', message: string } | null };

export type RegisterUserMutationVariables = Exact<{
  input?: InputMaybe<RegisterUserInput>;
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'RegisterUserOutput', message: string } | null };

export type GetAccessTokenFromRefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type GetAccessTokenFromRefreshTokenMutation = { __typename?: 'Mutation', getAccessTokenFromRefreshToken?: { __typename?: 'GetAccessTokenFromRefreshTokenOutput', access: string, user?: { __typename?: 'UserData', username: string, email: string } | null } | null };

export type ChangePasswordMutationVariables = Exact<{
  input?: InputMaybe<ChangeUserPwdInput>;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changeUserPwd?: { __typename?: 'ChangeUserPwdOutput', message: string } | null };

export type PasswordResetMutationVariables = Exact<{
  input?: InputMaybe<PasswordResetInput>;
}>;


export type PasswordResetMutation = { __typename?: 'Mutation', passwordReset?: { __typename?: 'PasswordResetOutput', message: string } | null };

export type PasswordResetConfirmMutationVariables = Exact<{
  input?: InputMaybe<PasswordResetConfirmInput>;
}>;


export type PasswordResetConfirmMutation = { __typename?: 'Mutation', passwordResetConfirm?: { __typename?: 'PasswordResetConfirmOutput', message: string } | null };

export type AcceptTermsAndConditionsMutationVariables = Exact<{
  input?: InputMaybe<AcceptTermsAndConditionsInput>;
}>;


export type AcceptTermsAndConditionsMutation = { __typename?: 'Mutation', acceptTermsAndConditions?: { __typename?: 'AcceptTermsAndConditionsOutput', success: boolean } | null };

export type EarningCallSummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type EarningCallSummariesQuery = { __typename?: 'Query', earningCallSummaries?: Array<{ __typename?: 'EarningCallSummaryType', authenticatedUrl: string, author: string, dislike?: number | null, feature?: string | null, like?: number | null, publishedQuarter?: string | null, publishedYear?: string | null, scrapedAt?: string | null, filepath?: string | null, summary: Array<{ __typename?: 'SummaryType', entity?: string | null, values: Array<{ __typename?: 'ValueType', subEntity?: string | null, subEntityValues: Array<string | null> } | null> } | null> } | null> | null };

export type IndustryNewsAndTrendsQueryVariables = Exact<{
  input?: InputMaybe<IndustryNewsAndTrendsInput>;
}>;


export type IndustryNewsAndTrendsQuery = { __typename?: 'Query', industryNewsAndTrends?: Array<{ __typename?: 'NewsType', _id?: string | null, url?: string | null, urlToImage?: string | null, title?: string | null, publishedAt?: string | null, description?: string | null, like?: number | null, dislike?: number | null, relevantContextImages?: Array<string | null> | null, summary?: string | null, category?: string | null, source?: { __typename?: 'NewsSourceType', name?: string | null } | null } | null> | null };

export type SourceDirectoryQueryVariables = Exact<{
  input?: InputMaybe<SourceDirectoryInput>;
}>;


export type SourceDirectoryQuery = { __typename?: 'Query', sourceDirectory?: Array<{ __typename?: 'SourceDirectoryType', categoryName: string, sources: Array<{ __typename?: 'SourceType', sourceName: string, sourceUrl: string } | null> } | null> | null };

export type ViewEcsSummaryQueryVariables = Exact<{
  input?: InputMaybe<ViewEcsSummaryInput>;
}>;


export type ViewEcsSummaryQuery = { __typename?: 'Query', viewEcsSummary?: { __typename?: 'ViewEcsSummaryOutput', base64Str?: string | null } | null };

export type GrowthMarketingTrendsQueryVariables = Exact<{
  input?: InputMaybe<GrowthMarketingInput>;
}>;


export type GrowthMarketingTrendsQuery = { __typename?: 'Query', growthMarketingTrends?: Array<{ __typename?: 'NewsType', _id?: string | null, url?: string | null, urlToImage?: string | null, title?: string | null, publishedAt?: string | null, description?: string | null, like?: number | null, dislike?: number | null, relevantContextImages?: Array<string | null> | null, summary?: string | null, category?: string | null, source?: { __typename?: 'NewsSourceType', name?: string | null } | null } | null> | null };

export type ViewArticlesAnalysisQueryVariables = Exact<{
  input?: InputMaybe<ViewArticlesAnalysisInput>;
}>;


export type ViewArticlesAnalysisQuery = { __typename?: 'Query', viewArticlesAnalysis?: { __typename?: 'ViewArticlesAnalysisOutput', analysisXlsBase64Str?: string | null } | null };

export type CheckIfUserAcceptedTermsQueryVariables = Exact<{
  input?: InputMaybe<CheckIfUserAcceptedTermsInput>;
}>;


export type CheckIfUserAcceptedTermsQuery = { __typename?: 'Query', checkIfUserAcceptedTerms?: { __typename?: 'CheckIfUserAcceptedTermsOutput', accepted: boolean } | null };

export type ViewLatestTrendsByCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewLatestTrendsByCategoryQuery = { __typename?: 'Query', viewLatestTrendsByCategory?: Array<{ __typename?: 'ViewLatestTrendsByCategoryOutput', category: string, categoryImg: string, keyHighlights?: Array<{ __typename?: 'KeyHighlightType', id: string, keyHighlight: string, url: string } | null> | null } | null> | null };


export const LikeNewsAndTrendsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LikeNewsAndTrends"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"LikeNewsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"likeNews"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"likeCount"}}]}}]}}]} as unknown as DocumentNode<LikeNewsAndTrendsMutation, LikeNewsAndTrendsMutationVariables>;
export const DislikeNewsAndTrendsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DislikeNewsAndTrends"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"DislikeNewsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dislikeNews"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dislikeCount"}},{"kind":"Field","name":{"kind":"Name","value":"_id"}}]}}]}}]} as unknown as DocumentNode<DislikeNewsAndTrendsMutation, DislikeNewsAndTrendsMutationVariables>;
export const LoginUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LoginUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"access"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<LoginUserMutation, LoginUserMutationVariables>;
export const LogoutUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LogoutUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logoutUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<LogoutUserMutation, LogoutUserMutationVariables>;
export const RegisterUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RegisterUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"RegisterUserInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"registerUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<RegisterUserMutation, RegisterUserMutationVariables>;
export const GetAccessTokenFromRefreshTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetAccessTokenFromRefreshToken"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAccessTokenFromRefreshToken"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"access"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<GetAccessTokenFromRefreshTokenMutation, GetAccessTokenFromRefreshTokenMutationVariables>;
export const ChangePasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangePassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeUserPwdInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeUserPwd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const PasswordResetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PasswordReset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PasswordResetInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"passwordReset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<PasswordResetMutation, PasswordResetMutationVariables>;
export const PasswordResetConfirmDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PasswordResetConfirm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PasswordResetConfirmInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"passwordResetConfirm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<PasswordResetConfirmMutation, PasswordResetConfirmMutationVariables>;
export const AcceptTermsAndConditionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"acceptTermsAndConditions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptTermsAndConditionsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptTermsAndConditions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<AcceptTermsAndConditionsMutation, AcceptTermsAndConditionsMutationVariables>;
export const EarningCallSummariesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EarningCallSummaries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"earningCallSummaries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticatedUrl"}},{"kind":"Field","name":{"kind":"Name","value":"author"}},{"kind":"Field","name":{"kind":"Name","value":"dislike"}},{"kind":"Field","name":{"kind":"Name","value":"feature"}},{"kind":"Field","name":{"kind":"Name","value":"like"}},{"kind":"Field","name":{"kind":"Name","value":"publishedQuarter"}},{"kind":"Field","name":{"kind":"Name","value":"publishedYear"}},{"kind":"Field","name":{"kind":"Name","value":"scrapedAt"}},{"kind":"Field","name":{"kind":"Name","value":"filepath"}},{"kind":"Field","name":{"kind":"Name","value":"summary"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"entity"}},{"kind":"Field","name":{"kind":"Name","value":"values"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subEntity"}},{"kind":"Field","name":{"kind":"Name","value":"subEntityValues"}}]}}]}}]}}]}}]} as unknown as DocumentNode<EarningCallSummariesQuery, EarningCallSummariesQueryVariables>;
export const IndustryNewsAndTrendsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"IndustryNewsAndTrends"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"IndustryNewsAndTrendsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"industryNewsAndTrends"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"urlToImage"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"source"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"like"}},{"kind":"Field","name":{"kind":"Name","value":"dislike"}},{"kind":"Field","name":{"kind":"Name","value":"relevantContextImages"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"category"}}]}}]}}]} as unknown as DocumentNode<IndustryNewsAndTrendsQuery, IndustryNewsAndTrendsQueryVariables>;
export const SourceDirectoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SourceDirectory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"SourceDirectoryInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sourceDirectory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"categoryName"}},{"kind":"Field","name":{"kind":"Name","value":"sources"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sourceName"}},{"kind":"Field","name":{"kind":"Name","value":"sourceUrl"}}]}}]}}]}}]} as unknown as DocumentNode<SourceDirectoryQuery, SourceDirectoryQueryVariables>;
export const ViewEcsSummaryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ViewEcsSummary"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ViewEcsSummaryInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewEcsSummary"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"base64Str"}}]}}]}}]} as unknown as DocumentNode<ViewEcsSummaryQuery, ViewEcsSummaryQueryVariables>;
export const GrowthMarketingTrendsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GrowthMarketingTrends"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GrowthMarketingInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"growthMarketingTrends"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"urlToImage"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"source"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"like"}},{"kind":"Field","name":{"kind":"Name","value":"dislike"}},{"kind":"Field","name":{"kind":"Name","value":"relevantContextImages"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"category"}}]}}]}}]} as unknown as DocumentNode<GrowthMarketingTrendsQuery, GrowthMarketingTrendsQueryVariables>;
export const ViewArticlesAnalysisDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ViewArticlesAnalysis"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ViewArticlesAnalysisInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewArticlesAnalysis"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"analysisXlsBase64Str"}}]}}]}}]} as unknown as DocumentNode<ViewArticlesAnalysisQuery, ViewArticlesAnalysisQueryVariables>;
export const CheckIfUserAcceptedTermsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"checkIfUserAcceptedTerms"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CheckIfUserAcceptedTermsInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkIfUserAcceptedTerms"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accepted"}}]}}]}}]} as unknown as DocumentNode<CheckIfUserAcceptedTermsQuery, CheckIfUserAcceptedTermsQueryVariables>;
export const ViewLatestTrendsByCategoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"viewLatestTrendsByCategory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewLatestTrendsByCategory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"categoryImg"}},{"kind":"Field","name":{"kind":"Name","value":"keyHighlights"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"keyHighlight"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode<ViewLatestTrendsByCategoryQuery, ViewLatestTrendsByCategoryQueryVariables>;