/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { SelectProps } from './types';
import { SelectBaseStyle, SelectMenuItem } from './styles';

export const Select = ({
  className,
  optionProps,
  value,
  name,
  placeholder,
  label,
  ...rest
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { onChange, disabled } = rest;

  useEffect(() => {
    setSelectedValue((currentValue) => (value as string) ?? currentValue);
  }, [value]);

  const handleChange = function (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const {
      target: { value: currentValue },
    } = evt;
    setSelectedValue(currentValue);
    onChange?.(evt as unknown as React.ChangeEvent<HTMLSelectElement>);
  };

  return (
    <FormControl disabled={disabled} css={SelectBaseStyle} size="small">
      <TextField
        select
        disabled={disabled}
        id="select-dropdown"
        name={name}
        value={selectedValue}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
        className={className}
        label={label}
        size="small"
        placeholder={placeholder}
      >
        {optionProps.map(({ value, label }) => (
          <MenuItem key={label} value={value} css={SelectMenuItem}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
