/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { ClassNames } from '@emotion/react';
import { useLazyQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { AppConstants } from '../../../constants';
import { LargeTextStyle, SmallTextStyle } from '../../../typography';
import { GET_GROWTH_MARKETING_TRENDS } from '../../../middleware/queries';
import {
  GrowthMarketingTrendsQuery,
  NewsType,
} from '../../../__generated__/graphql';
import { NewsCard } from '../common';
import {
  Carousel,
  Card,
  Button,
  Select,
  NoResultsFound,
  Notification,
} from '../../common';
import { ButtonThemeTypes } from '../../common/Button/types';
import { Option } from '../../common/Select/types';
import { GrowthMarketingProps } from './types';
import {
  CardImgSkeleton,
  HeaderAndDescription,
  GrowthMarketingDesc,
  GrowthMarketingToolbarContainer,
  LoaderContainer,
  NewsCardContainer,
  NewsCardImage,
  RangeSelector,
  RegionBtn,
  RegionSelectorContainer,
  SkeletonCardBaseStyle,
  SkeletonCardFooterContainer,
  SkeletonCardSubtitle,
  SkeletonCardSubtitleContainer,
  SkeletonCardTitle,
  SkeletonCardTitleContainer,
} from './styles';

const {
  insightsForGrowthTab: {
    sections: { insightsForGrowth },
  },
} = AppConstants;

const {
  description,
  title,
  regions,
  carousel: { pageSize },
  noResultsDesc,
  selectAll,
  filters: { categories, week },
} = insightsForGrowth;

const generateNthSunday = function (n: number): Date {
  const curr = new Date();
  curr.setDate(curr.getDate() - curr.getDay() - 7 * n);
  return curr;
};

const generateDateLabel = function (nthDate: Date): string {
  const nthLabelMonth = nthDate.toLocaleString('default', {
    month: 'short',
  });
  const nthLabelDate = nthDate.getDate();
  return `${nthLabelMonth} ${nthLabelDate}`;
};

const generateTrendRangeOptions = function (totalLen = 6): Option[] {
  return Array.from({ length: totalLen }, (_, i) => i + 1).map((i) => {
    const nthLastSunday = generateNthSunday(i);
    const nthLastSundayLabel = generateDateLabel(nthLastSunday);

    const nthLastSaturday = new Date(
      new Date(nthLastSunday).setDate(nthLastSunday.getDate() + 6)
    );

    const nthLastSaturdayLabel = generateDateLabel(nthLastSaturday);

    const value = new Date(
      new Date(nthLastSunday).setDate(nthLastSunday.getDate() + 10)
    )
      .toLocaleString()
      .split(', ')[0]
      .split('/')
      .join('-');

    return {
      label: `${nthLastSundayLabel} - ${nthLastSaturdayLabel}`,
      value,
    };
  });
};

export const GrowthMarketing = ({ maxDataLimit }: GrowthMarketingProps) => {
  const [growthStrategyData, setGrowthStrategyData] = useState<
    GrowthMarketingTrendsQuery['growthMarketingTrends']
  >([]);

  const tagsOptions: Option[] = useMemo(
    () => [
      { label: selectAll, value: selectAll },
      ...[
        ...new Set(
          growthStrategyData?.map((growthData) => growthData?.category)
        ),
      ].map((val) => ({ label: val, value: val }) as Option),
    ],
    [growthStrategyData]
  );

  const rangeOptions = useMemo(
    () => [
      ...generateTrendRangeOptions(),
      {
        label: selectAll,
        value: generateTrendRangeOptions().map(({ value }) => value as string),
      },
    ],
    []
  );

  const [selectedRange, setSelectedRange] = useState<string[] | string>(
    rangeOptions[0]?.value
  );
  const [selectedTagFilter, setSelectedTagFilter] = useState<string>(selectAll);
  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false);

  const [getGrowthStrategies, { loading, error }] = useLazyQuery(
    GET_GROWTH_MARKETING_TRENDS
  );

  const allGrowthMarketingStrategies = useMemo(() => {
    const data =
      (growthStrategyData?.slice(0, maxDataLimit ?? undefined) as NewsType[]) ||
      [];
    return selectedTagFilter === selectAll || !selectedTagFilter
      ? data
      : data.filter((dta) => dta.category === selectedTagFilter);
  }, [growthStrategyData, selectedTagFilter]);

  useEffect(() => {
    error?.message && setOpenErrorAlert(true);
  }, [error]);

  useEffect(() => {
    let isMounted = true;
    const growthStrategyAsync = async function () {
      const { data } = await getGrowthStrategies({
        fetchPolicy: 'network-only',
        variables: {
          input: {
            currentDate: selectedRange as string,
          },
        },
      });
      setGrowthStrategyData(data?.growthMarketingTrends || []);
    };
    if (isMounted) {
      growthStrategyAsync();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleErrorAlertClose = function () {
    setOpenErrorAlert(false);
  };

  const handleRangeChange = async function (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) {
    setSelectedRange(evt.target.value);
    setSelectedTagFilter(selectAll);
    if (Array.isArray(evt.target.value)) {
      const combinedList = [];
      for (const val of evt.target.value) {
        const { data } = await getGrowthStrategies({
          fetchPolicy: 'network-only',
          variables: {
            input: {
              currentDate: val,
            },
          },
        });
        combinedList.push(...(data?.growthMarketingTrends || []));
      }
      setGrowthStrategyData(combinedList);
    } else {
      const { data } = await getGrowthStrategies({
        fetchPolicy: 'network-only',
        variables: {
          input: {
            currentDate: evt.target.value,
          },
        },
      });
      setGrowthStrategyData(data?.growthMarketingTrends || []);
    }
  };

  const handleTagFilterChange = function (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) {
    handleRangeChange({
      target: { value: selectedRange as string },
    } as unknown as React.ChangeEvent<HTMLSelectElement>);
    setSelectedTagFilter(evt.target.value);
  };

  if (error) {
    return (
      <Notification
        {...{
          open: openErrorAlert,
          onClose: handleErrorAlertClose,
          type: 'error',
        }}
      >
        {error.message}
      </Notification>
    );
  }

  return (
    <ClassNames>
      {({ css }) => (
        <div>
          {!maxDataLimit && (
            <ul css={GrowthMarketingToolbarContainer}>
              <li>
                <ul css={RegionSelectorContainer}>
                  {regions.map((r) => (
                    <li key={r}>
                      <Button
                        disabled
                        theme={ButtonThemeTypes.Dark}
                        css={RegionBtn}
                      >
                        {r}
                      </Button>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <ul css={GrowthMarketingToolbarContainer}>
                  <li>
                    <Select
                      {...{
                        label: categories,
                        className: css`
                          ${RangeSelector}
                        `,
                        value: selectedTagFilter,
                        disabled: loading,
                        onChange: handleTagFilterChange,
                        optionProps: tagsOptions,
                      }}
                    />
                  </li>
                  <li>
                    <Select
                      {...{
                        label: week,
                        className: css`
                          ${RangeSelector}
                        `,
                        value: selectedRange,
                        disabled: loading,
                        onChange: handleRangeChange,
                        optionProps: rangeOptions,
                      }}
                    />
                  </li>
                </ul>
              </li>
            </ul>
          )}
          <div css={[LargeTextStyle, HeaderAndDescription]}>{title}</div>
          <div
            css={[SmallTextStyle, GrowthMarketingDesc, HeaderAndDescription]}
          >
            {description}
          </div>
          {allGrowthMarketingStrategies?.length > 0 || loading ? (
            <Carousel
              {...{
                children: (data: NewsType[]) => {
                  return (
                    <>
                      {data?.map((news, idx) => {
                        return (
                          <div
                            key={`${news.title}-${idx}`}
                            css={NewsCardContainer}
                          >
                            <NewsCard {...{ ...news }} />
                          </div>
                        );
                      })}
                    </>
                  );
                },
                data: allGrowthMarketingStrategies,
                isDataLoading: loading,
                itemsPerPage: maxDataLimit ?? pageSize,
                shouldScrollToContent: !maxDataLimit,
                loader: (
                  <>
                    {Array.from({ length: maxDataLimit ?? pageSize }).map(
                      (_, i) => {
                        return (
                          <div key={i} css={LoaderContainer}>
                            <Card
                              {...{
                                cardImg: (
                                  <Skeleton
                                    {...{
                                      animation: 'wave',
                                      variant: 'rectangular',
                                      css: CardImgSkeleton,
                                    }}
                                  />
                                ),
                                cardImgClassName: css`
                                  ${NewsCardImage}
                                `,
                                cardTitle: (
                                  <div css={SkeletonCardTitleContainer}>
                                    <Skeleton
                                      {...{
                                        animation: 'wave',
                                        variant: 'text',
                                        css: SkeletonCardTitle,
                                      }}
                                    />
                                  </div>
                                ),
                                cardSubTitle: (
                                  <div css={SkeletonCardSubtitleContainer}>
                                    <Skeleton
                                      {...{
                                        animation: 'wave',
                                        variant: 'text',
                                        css: SkeletonCardSubtitle,
                                      }}
                                    />
                                  </div>
                                ),
                                className: css`
                                  ${SkeletonCardBaseStyle}
                                `,
                                cardFooterClassName: css`
                                  ${SkeletonCardFooterContainer}
                                `,
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                  </>
                ),
              }}
            />
          ) : (
            <NoResultsFound
              {...{
                description: noResultsDesc,
              }}
            />
          )}
        </div>
      )}
    </ClassNames>
  );
};
