import { css } from '@emotion/react';

export const CheckBoxLabel = css({
  marginLeft: 'var(--yt-size-3xl)',
});

export const CheckBoxInput = css({
  marginRight: 'var( --yt-size-ml)',
  cursor: 'pointer',
});
