/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { UserDetailsProps } from '../../auth-context/types';
import { SecuredRouteProps } from './types';
import { YtAuthContext } from '../../auth-context/context';
import { GET_ACCESS_TOKEN } from '../../middleware/mutations';
import LoginForm from './LoginForm';
import { AppConstants } from '../../constants';
import { PageLoadProgress } from './styles';

const { login } = AppConstants;

export default function SecuredRoute({ children }: SecuredRouteProps) {
  const [isSecured, setSecured] = useState<boolean>(true);
  const [loadingSecured, setLoadingSecured] = useState<boolean>(true);
  const navigate = useNavigate();
  const { authAccessToken, setAuthAccessToken, setUserDetails } =
    useContext(YtAuthContext);
  const [getAccessToken] = useMutation(GET_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const userDetails = data?.getAccessTokenFromRefreshToken
        ?.user as UserDetailsProps;
      setUserDetails((user) =>
        user?.email !== userDetails?.email ||
        user?.username !== userDetails?.username
          ? userDetails
          : user
      );
      setAuthAccessToken(
        data?.getAccessTokenFromRefreshToken?.access as string
      );
      setLoadingSecured(false);
    },
    onError: () => {
      setSecured(false);
      setLoadingSecured(false);
      navigate(`/${login}`, {
        replace: true,
      });
    },
  });

  useEffect(() => {
    const timer: ReturnType<typeof setInterval> = setInterval(() => {
      getAccessToken();
    }, 3000);
    return () => clearInterval(timer);
  }, [authAccessToken]);

  return loadingSecured ? (
    <LinearProgress css={PageLoadProgress} />
  ) : isSecured && authAccessToken ? (
    children
  ) : (
    <LoginForm />
  );
}
