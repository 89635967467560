import React from 'react';
import { NoResultsFoundProps } from './types';
import { ClassNames } from '@emotion/react';
import { AppConstants } from '../../../constants';
import {
  BaseContainer,
  DescriptionText,
  NoResultsImg,
  NoResultsText,
} from './styles';

const { noResultsFound } = AppConstants;

export const NoResultsFound = ({
  description,
  className,
  descClassName,
}: NoResultsFoundProps) => {
  return (
    <ClassNames>
      {({ cx, css }) => {
        return (
          <div
            className={cx(
              css`
                ${BaseContainer}
              `,
              className
            )}
          >
            <div>
              <img
                src={'./no_results_logo.jpg'}
                className={css`
                  ${NoResultsImg}
                `}
              />
            </div>
            <div
              className={css`
                ${NoResultsText}
              `}
            >
              {noResultsFound}
            </div>
            <div
              className={cx(
                css`
                  ${DescriptionText}
                `,
                descClassName
              )}
            >
              {description}
            </div>
          </div>
        );
      }}
    </ClassNames>
  );
};
