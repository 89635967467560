/** @jsxImportSource @emotion/react */
import React from 'react';
import { CardProps } from './types';
import { ClassNames } from '@emotion/react';
import {
  CardBaseStyle,
  CardDescriptionStyle,
  CardFooterStyle,
  CardImgStyle,
  CardSubTitleStyle,
  CardTitleStyle,
} from './styles';

export const Card = ({
  cardImg,
  cardImgAlt,
  cardImgClassName,
  cardTitle,
  cardSubTitle,
  cardDescription,
  cardFooter,
  cardFooterClassName,
  className,
  onClick,
}: CardProps) => {
  return (
    <ClassNames>
      {({ css, cx }) => {
        return (
          <div
            {...{
              className: cx(
                css`
                  ${CardBaseStyle}
                `,
                className
              ),
              onClick,
            }}
          >
            {!!cardImg && (
              <div>
                {typeof cardImg === 'string' ? (
                  <img
                    {...{
                      src: cardImg,
                      alt: cardImgAlt,
                      className: cx(
                        css`
                          ${CardImgStyle}
                        `,
                        cardImgClassName
                      ),
                    }}
                  />
                ) : (
                  cardImg
                )}
              </div>
            )}
            {!!(cardTitle || cardSubTitle) && (
              <div>
                {!!cardTitle && (
                  <header
                    className={css`
                      ${CardTitleStyle}
                    `}
                  >
                    {cardTitle}
                  </header>
                )}
                {!!cardSubTitle && (
                  <header
                    className={css`
                      ${CardSubTitleStyle}
                    `}
                  >
                    {cardSubTitle}
                  </header>
                )}
              </div>
            )}
            {!!cardDescription && (
              <div
                className={css`
                  ${CardDescriptionStyle}
                `}
              >
                {cardDescription}
              </div>
            )}
            {!!cardFooter && (
              <div
                className={cx(
                  css`
                    ${CardFooterStyle};
                  `,
                  cardFooterClassName
                )}
              >
                {cardFooter}
              </div>
            )}
          </div>
        );
      }}
    </ClassNames>
  );
};
