import { gql } from '../__generated__';

export const GET_EARNING_CALL_SUMMARIES = gql(/* GraphQL */ `
  query EarningCallSummaries {
    earningCallSummaries {
      authenticatedUrl
      author
      dislike
      feature
      like
      publishedQuarter
      publishedYear
      scrapedAt
      filepath
      summary {
        entity
        values {
          subEntity
          subEntityValues
        }
      }
    }
  }
`);

export const GET_INDUSTRY_NEWS_TRENDS = gql(/* GraphQL */ `
  query IndustryNewsAndTrends($input: IndustryNewsAndTrendsInput) {
    industryNewsAndTrends(input: $input) {
      _id
      url
      urlToImage
      title
      source {
        name
      }
      publishedAt
      description
      like
      dislike
      relevantContextImages
      summary
      category
    }
  }
`);

export const GET_SOURCES = gql(/* GraphQL */ `
  query SourceDirectory($input: SourceDirectoryInput) {
    sourceDirectory(input: $input) {
      categoryName
      sources {
        sourceName
        sourceUrl
      }
    }
  }
`);

export const GET_ECS_SUMMARY_DOC = gql(/* GraohQL */ `
  query ViewEcsSummary($input: ViewEcsSummaryInput) {
    viewEcsSummary(input: $input) {
      base64Str
    }
  }
`);

export const GET_GROWTH_MARKETING_TRENDS = gql(/* GraphQL */ `
  query GrowthMarketingTrends($input: GrowthMarketingInput) {
    growthMarketingTrends(input: $input) {
      _id
      url
      urlToImage
      title
      source {
        name
      }
      publishedAt
      description
      like
      dislike
      relevantContextImages
      summary
      category
    }
  }
`);

export const GET_ARTICLES_ANALYSIS_DOC = gql(/* GraphQL */ `
  query ViewArticlesAnalysis($input: ViewArticlesAnalysisInput) {
    viewArticlesAnalysis(input: $input) {
      analysisXlsBase64Str
    }
  }
`);

export const CHECK_IF_USER_ACCEPTED_TERMS_AND_CONDITIONS = gql(/* GraphQL */ `
  query checkIfUserAcceptedTerms($input: CheckIfUserAcceptedTermsInput) {
    checkIfUserAcceptedTerms(input: $input) {
      accepted
    }
  }
`);

export const VIEW_LATEST_TRENDS_BY_CATEGORY = gql(/* GraphQL */ `
  query viewLatestTrendsByCategory {
    viewLatestTrendsByCategory {
      category
      categoryImg
      keyHighlights {
        id
        keyHighlight
        url
      }
    }
  }
`);
