/** @jsxImportSource @emotion/react */
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { SummaryCard } from './SummaryCard';
import { breakpoints } from '../../../breakpoints';
import { SummaryModalProps } from './types';
import { SummaryType } from '../../../__generated__/graphql';
import { Popup } from '../../common';

export const ViewSummaryModal = ({
  open,
  onClose,
  publishedQuarter,
  publishedYear,
  summary,
}: SummaryModalProps) => {
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints[1]}px)`);

  return (
    <Popup
      {...{
        open,
        onClose,
        closeAfterTransition: true,
        scroll: 'body',
        fullScreen: isSmallScreen,
        'aria-describedby': 'alert-dialog-slide-description',
      }}
    >
      <SummaryCard
        {...{
          publishedQuarter,
          publishedYear,
          summary: summary as SummaryType[],
          isTruncated: false,
          handleSummaryModalClose: onClose,
        }}
      />
    </Popup>
  );
};
