import React from 'react';
import { ClassNames } from '@emotion/react';
import { ButtonProps, ButtonThemeTypes, ButtonVariantTypes } from './types';
import { ButtonBaseStyle, PrePostIconStyle } from './styles';

export const Button = ({
  className,
  variant = ButtonVariantTypes.Primary,
  theme = ButtonThemeTypes.Light,
  iconPre,
  iconPost,
  children,
  ...restProps
}: ButtonProps) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <button
          {...{
            className: cx(
              css`
                ${ButtonBaseStyle(variant, theme)}
              `,
              className
            ),
            ...restProps,
          }}
        >
          {iconPre && (
            <span
              className={css`
                ${PrePostIconStyle}
              `}
            >
              {iconPre}
            </span>
          )}
          {children && <span>{children}</span>}
          {iconPost && (
            <span
              className={css`
                ${PrePostIconStyle}
              `}
            >
              {iconPost}
            </span>
          )}
        </button>
      )}
    </ClassNames>
  );
};
