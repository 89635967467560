import React, { useState } from 'react';
import { UserDetailsProps, UserProps, YtAuthProps } from './types';

export const YtAuthContext = React.createContext<UserProps>({
  userDetails: {
    email: '',
    username: '',
  },
  authAccessToken: '',
  setUserDetails: () => {
    /* */
  },
  setAuthAccessToken: () => {
    /* */
  },
});

export default function YtAuth({ children }: YtAuthProps) {
  const [authAccessToken, setAuthAccessToken] = useState<string>('');
  const [userDetails, setUserDetails] = useState<UserDetailsProps>({
    email: '',
    username: '',
  });
  return (
    <YtAuthContext.Provider
      value={{
        authAccessToken,
        userDetails,
        setAuthAccessToken,
        setUserDetails,
      }}
    >
      {children}
    </YtAuthContext.Provider>
  );
}
