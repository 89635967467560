import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { AppConstants } from './constants';
import { InsightsLanding, ArticlesAnalysis } from './pages';
import LoginForm from './components/AuthComponents/LoginForm';
import SecuredRoute from './components/AuthComponents/SecuredRoute';
import PasswordChange from './components/AuthComponents/PasswordChange';
import PasswordReset from './components/AuthComponents/PasswordReset';
import PasswordResetDone from './components/AuthComponents/PasswordResetDone';
import PasswordResetConfirm from './components/AuthComponents/PasswordResetConfirm';
import PasswordResetComplete from './components/AuthComponents/PasswordResetComplete';

const {
  insightsLandingTabUrls,
  viewArticlesAnalysisUrl,
  login,
  passwordChange,
  passwordReset,
  passwordResetDone,
  passwordResetConfirm,
  passwordResetComplete,
} = AppConstants;

export const routes: RouteObject[] = [
  ...insightsLandingTabUrls.map((url, idx) => ({
    path: url,
    element: (
      <SecuredRoute>
        <InsightsLanding selectedTabIdx={idx} />
      </SecuredRoute>
    ),
  })),
  {
    path: viewArticlesAnalysisUrl,
    element: (
      <SecuredRoute>
        <ArticlesAnalysis />
      </SecuredRoute>
    ),
  },
  {
    path: login,
    element: <LoginForm />,
  },
  {
    path: passwordChange,
    element: (
      <SecuredRoute>
        <PasswordChange />
      </SecuredRoute>
    ),
  },
  {
    path: passwordReset,
    element: <PasswordReset />,
  },
  {
    path: passwordResetDone,
    element: <PasswordResetDone />,
  },
  {
    path: passwordResetConfirm,
    element: <PasswordResetConfirm />,
  },
  {
    path: passwordResetComplete,
    element: <PasswordResetComplete />,
  },
  {
    path: '*',
    element: <Navigate to={login} replace />,
  },
];
