/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ClassNames } from '@emotion/react';
import { Skeleton, useMediaQuery } from '@mui/material';
import { AppConstants } from '../../../constants';
// import { GrowthMarketing } from '../GrowthMarketing';
// import { IndustryTrendsNews } from '../IndustryTrendsNews';
// import { Section } from './Section';
import { VIEW_LATEST_TRENDS_BY_CATEGORY } from '../../../middleware/queries';
import { Card, ExternalLink, NoResultsFound, Notification } from '../../common';
import { breakpoints } from '../../../breakpoints';
import {
  CardImgSkeleton,
  CategoryCard,
  CategoryCardTitle,
  HighlightItem,
  HighlightsList,
  LatestTrendsContainer,
  LoaderContainer,
  CategoryCardImage,
  ReadMoreLink,
  SkeletonCardBaseStyle,
  SkeletonCardFooterContainer,
  SkeletonCardSubtitle,
  SkeletonCardSubtitleContainer,
  SkeletonCardTitle,
  SkeletonCardTitleContainer,
  NoResultFoundStyle,
  HighlightItemContainer,
} from './styles';

const {
  readMore,
  latestTrendsTab: {
    defaultNoOfItems,
    defaultNoOfItemsForSmallScreens,
    noResultsDesc,
  },
} = AppConstants;

export const LatestTrends = () => {
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints[1]}px)`);
  const [openErrorNotification, setErrorNotification] =
    useState<boolean>(false);
  const { loading, error, data } = useQuery(VIEW_LATEST_TRENDS_BY_CATEGORY, {
    fetchPolicy: 'network-only',
  });

  const handleClose = function () {
    setErrorNotification(false);
  };

  const handleReadMoreClick = useCallback(function (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    evt?.stopPropagation();
  }, []);

  useEffect(() => {
    error && error?.message && setErrorNotification(true);
  }, [error]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const latestTrends = data?.viewLatestTrendsByCategory || [];

  const noOfDefaultItems = isSmallScreen
    ? defaultNoOfItemsForSmallScreens
    : defaultNoOfItems;

  return (
    <div css={LatestTrendsContainer}>
      {openErrorNotification && (
        <Notification
          {...{
            open: !!error?.message,
            type: 'error',
            onClose: handleClose,
          }}
        >
          {error?.message}
        </Notification>
      )}

      <ClassNames>
        {({ css }) => (
          <>
            {loading ? (
              Array.from({ length: noOfDefaultItems }).map((_, i) => {
                return (
                  <div key={i} css={LoaderContainer}>
                    <Card
                      {...{
                        cardImg: (
                          <Skeleton
                            {...{
                              animation: 'wave',
                              variant: 'rectangular',
                              css: CardImgSkeleton,
                            }}
                          />
                        ),
                        cardImgClassName: css`
                          ${CategoryCardImage}
                        `,
                        cardTitle: (
                          <div css={SkeletonCardTitleContainer}>
                            <Skeleton
                              {...{
                                animation: 'wave',
                                variant: 'text',
                                css: SkeletonCardTitle,
                              }}
                            />
                          </div>
                        ),
                        cardSubTitle: (
                          <div css={SkeletonCardSubtitleContainer}>
                            <Skeleton
                              {...{
                                animation: 'wave',
                                variant: 'text',
                                css: SkeletonCardSubtitle,
                              }}
                            />
                            <Skeleton
                              {...{
                                animation: 'wave',
                                variant: 'text',
                                css: SkeletonCardSubtitle,
                              }}
                            />
                          </div>
                        ),
                        className: css`
                          ${SkeletonCardBaseStyle}
                        `,
                        cardFooterClassName: css`
                          ${SkeletonCardFooterContainer}
                        `,
                      }}
                    />
                  </div>
                );
              })
            ) : latestTrends?.length > 0 ? (
              latestTrends?.map((trend) => {
                return (
                  <Card
                    key={trend?.category}
                    css={CategoryCard}
                    {...{
                      cardImg: trend?.categoryImg,
                      cardImgClassName: css`
                        ${CategoryCardImage}
                      `,
                      cardTitle: (
                        <div css={CategoryCardTitle}>
                          <span>{trend?.category}</span>
                        </div>
                      ),
                      cardDescription: (
                        <ol css={HighlightsList}>
                          {trend?.keyHighlights?.map((highlight) => (
                            <li key={highlight?.id} css={HighlightItem}>
                              <div css={HighlightItemContainer}>
                                <span>{highlight?.keyHighlight}</span>
                                <ExternalLink
                                  href={highlight?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  css={ReadMoreLink}
                                  onClick={handleReadMoreClick}
                                >
                                  {readMore}
                                </ExternalLink>
                              </div>
                            </li>
                          ))}
                        </ol>
                      ),
                    }}
                  />
                );
              })
            ) : (
              <NoResultsFound
                {...{
                  description: noResultsDesc,
                  className: css`
                    ${NoResultFoundStyle}
                  `,
                }}
              />
            )}
          </>
        )}
      </ClassNames>

      {/* {Object.entries(sections).map((entry, idx) => {
        switch (entry[0]) {
          case 'insightsForGrowth':
            return (
              <Section
                key={'insightsForGrowth'}
                {...{
                  navigateToUrlBtnText: entry[1].viewMoreBtnText,
                  tabUrl: insightsLandingTabUrls[idx + 1],
                }}
              >
                <GrowthMarketing
                  {...{ maxDataLimit: maxDataLimitPerSection }}
                />
              </Section>
            );
          case 'industryTrends':
            return (
              <Section
                key={'industryTrends'}
                {...{
                  navigateToUrlBtnText: entry[1].viewMoreBtnText,
                  tabUrl: insightsLandingTabUrls[idx + 1],
                  isLastSection: true,
                }}
              >
                <IndustryTrendsNews
                  {...{ maxDataLimit: maxDataLimitPerSection }}
                />
              </Section>
            );
          default:
            return (
              <Section
                key={'industryTrends'}
                {...{
                  navigateToUrlBtnText: entry[1].viewMoreBtnText,
                  tabUrl: insightsLandingTabUrls[idx + 1],
                  isLastSection: true,
                }}
              >
                <IndustryTrendsNews
                  {...{ maxDataLimit: maxDataLimitPerSection }}
                />
              </Section>
            );
        }
      })} */}
    </div>
  );
};
