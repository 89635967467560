import React from 'react';
import { ClassNames } from '@emotion/react';
import { CheckBoxComponentProps } from './types';
import { CheckBoxInput, CheckBoxLabel } from './styles';

const CheckBox = ({
  label,
  checked,
  checkBoxInputClassName,
  checkBoxLabelClassName,
  onCheckBoxChange,
}: CheckBoxComponentProps) => {
  return (
    <ClassNames>
      {({ css }) => (
        <label
          {...{
            className: css(CheckBoxLabel, checkBoxLabelClassName),
          }}
        >
          <input
            {...{
              className: css(CheckBoxInput, checkBoxInputClassName),
              type: 'checkbox',
              checked,
              onChange: onCheckBoxChange,
            }}
          />
          <span>{label}</span>
        </label>
      )}
    </ClassNames>
  );
};

export default CheckBox;
