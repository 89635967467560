/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box, CircularProgress, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { ClassNames } from '@emotion/react';
import { Button, Notification } from '../common';
import { ButtonVariantTypes } from '../common/Button/types';
import { AppConstants } from '../../constants';
import { LOGIN_USER } from '../../middleware/mutations';
import { YtAuthContext } from '../../auth-context/context';
import { UserDetailsProps } from '../../auth-context/types';
import {
  FooterContainer,
  FooterLink,
  FormContainer,
  SignInOrRegisterBtn,
  SignInOrRegisterProgress,
} from './styles';

const { loginPage, insightsLandingTabUrls, passwordReset } = AppConstants;

const { forgotPwd, header, signInBtnText, signInLoading } = loginPage;

const siteKey = process.env.REACT_APP_SITE_KEY;

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthAccessToken, setUserDetails } = useContext(YtAuthContext);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [shouldShowRegNotification, setShouldShowRegNotification] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      location.state?.registrationSuccessful ||
      location.state?.passwordResetSuccessful ||
      location.state?.passwordResetConfirmSuccessful
    ) {
      setShouldShowRegNotification(true);
    }
  }, [location.state]);

  const [doLogin, { loading, error }] = useMutation(LOGIN_USER, {
    onError: () => {
      recaptchaRef?.current?.reset();
      return;
    },
  });

  const handleCloseRegNotification = function () {
    setShouldShowRegNotification(false);
    navigate('/', {
      state: null,
    });
  };

  const handleSubmit = async function (
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    if (!recaptchaRef?.current?.getValue()) {
      return;
    }
    const data = new FormData(event.currentTarget);
    const res = await doLogin({
      variables: {
        input: {
          email: data.get('email') as string,
          password: data.get('password') as string,
          captchaValue: recaptchaRef.current.getValue() as string,
        },
      },
    });
    if (res.data?.loginUser?.access) {
      setUserDetails(res.data?.loginUser?.user as UserDetailsProps);
      setAuthAccessToken(res.data?.loginUser?.access);
      navigate(`/${insightsLandingTabUrls[0]}`, {
        replace: true,
      });
    }
  };

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <div css={FormContainer}>
            <h2>{header}</h2>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                color="error"
                disabled={loading}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                color="error"
                disabled={loading}
              />
              <Button
                type="submit"
                variant={ButtonVariantTypes.Primary}
                iconPre={
                  loading && (
                    <CircularProgress
                      size={15}
                      css={SignInOrRegisterProgress}
                    />
                  )
                }
                disabled={loading}
                className={css`
                  ${SignInOrRegisterBtn}
                `}
              >
                {loading ? signInLoading : signInBtnText}
              </Button>
              <div css={FooterContainer}>
                <Link to={loading ? '#' : `/${passwordReset}`} css={FooterLink}>
                  {forgotPwd}
                </Link>
              </div>
              {siteKey && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="normal"
                  css={FooterContainer}
                  {...{ sitekey: siteKey }}
                />
              )}
            </Box>
          </div>
          {!!error?.message && (
            <Notification
              {...{
                open: !!error.message,
                type: 'error',
              }}
            >
              {error.message}
            </Notification>
          )}
          {!!location.state?.registrationSuccessful && (
            <Notification
              {...{
                open: shouldShowRegNotification,
                autoHideDuration: 3000,
                onClose: handleCloseRegNotification,
                type: 'success',
              }}
            >
              {location?.state?.registrationSuccessful}
            </Notification>
          )}
          {!!location.state?.passwordResetSuccessful && (
            <Notification
              {...{
                open: shouldShowRegNotification,
                autoHideDuration: 3000,
                onClose: handleCloseRegNotification,
                type: 'success',
              }}
            >
              {location?.state?.passwordResetSuccessful}
            </Notification>
          )}
          {!!location.state?.passwordResetConfirmSuccessful && (
            <Notification
              {...{
                open: shouldShowRegNotification,
                autoHideDuration: 3000,
                onClose: handleCloseRegNotification,
                type: 'success',
              }}
            >
              {location?.state?.passwordResetConfirmSuccessful}
            </Notification>
          )}
        </>
      )}
    </ClassNames>
  );
};
export default LoginForm;
