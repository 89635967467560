/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { ClassNames } from '@emotion/react';
import { Grow } from '@mui/material';
import { ExternalLink, Card } from '../../common';
import { AppConstants } from '../../../constants';
import { NewsType } from '../../../__generated__/graphql';
import { NewsModal } from './NewsModal';
import { NewsActionsToolbar } from './NewsActionsToolbar';
import {
  initialiseLikeDislikeObj,
  likeDislikeReducer,
} from '../common/context/helpers';
import { LikeDislikeContext } from '../common/context/LikeDislikeContext';
import { LikeDislikeActionTypes } from './types';
import {
  Category,
  NewsCardFooterBaseStyle,
  NewsCardBaseStyle,
  NewsCardDescriptionContainer,
  NewsCardGrowAnimateContainer,
  NewsCardImage,
  NewsCardSubTitle,
  NewsCardTitle,
  ReadMoreLink,
} from './styles';

const { readMore } = AppConstants;

export const NewsCard = ({
  title,
  urlToImage,
  source,
  publishedAt,
  description,
  summary,
  relevantContextImages,
  url,
  like,
  dislike,
  _id,
  category,
}: NewsType) => {
  const [openNewsModal, setOpenNewsModal] = useState<boolean>(false);
  const [likeDislikeObj, likeDislikeDispatch] = useReducer(
    likeDislikeReducer,
    initialiseLikeDislikeObj({ like: 0, dislike: 0 })
  );

  useEffect(() => {
    like !== null &&
      like !== undefined &&
      likeDislikeDispatch({
        type: LikeDislikeActionTypes.UpdateLikeCount,
        like,
      });
    dislike !== null &&
      dislike !== undefined &&
      likeDislikeDispatch({
        type: LikeDislikeActionTypes.UpdateDislikeCount,
        dislike,
      });
  }, [like, dislike]);

  const handleOpenNewsModal = useCallback(function () {
    setOpenNewsModal(true);
  }, []);

  const handleCloseNewsModal = useCallback(function () {
    setOpenNewsModal(false);
  }, []);

  const handleReadMoreClick = useCallback(function (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    evt?.stopPropagation();
  }, []);

  const publishedDate = new Date(publishedAt || '');
  const publishedMonth = publishedDate.toLocaleString('default', {
    month: 'long',
  });
  const humanReadblePublishedAt = `${publishedMonth} ${publishedDate.getDate()}, ${publishedDate.getFullYear()}`;

  return (
    <LikeDislikeContext.Provider
      value={{ likeDislikeObj, likeDislikeDispatch }}
    >
      <ClassNames>
        {({ css }) => {
          return (
            <>
              <Grow in timeout={500}>
                <div css={NewsCardGrowAnimateContainer}>
                  <Card
                    key={title}
                    {...{
                      cardImg: urlToImage,
                      cardImgClassName: css`
                        ${NewsCardImage}
                      `,
                      cardTitle: (
                        <div css={NewsCardTitle}>
                          <span css={Category}>{category}</span>
                          <span>{title}</span>
                        </div>
                      ),
                      cardSubTitle: (
                        <div css={NewsCardSubTitle}>
                          {source?.name}, {humanReadblePublishedAt}
                        </div>
                      ),
                      cardDescription: (
                        <div css={NewsCardDescriptionContainer}>
                          {description}
                          {url && (
                            <ExternalLink
                              href={url}
                              target="_blank"
                              rel="noreferrer"
                              css={ReadMoreLink}
                              onClick={handleReadMoreClick}
                            >
                              {readMore}
                            </ExternalLink>
                          )}
                        </div>
                      ),
                      cardFooter: (
                        <NewsActionsToolbar
                          {...{
                            _id,
                          }}
                        />
                      ),
                      className: css`
                        ${NewsCardBaseStyle}
                      `,
                      cardFooterClassName: css`
                        ${NewsCardFooterBaseStyle}
                      `,
                      onClick: handleOpenNewsModal,
                    }}
                  />
                </div>
              </Grow>
              <NewsModal
                {...{
                  onClose: handleCloseNewsModal,
                  open: openNewsModal,
                  title,
                  source,
                  summary,
                  relevantContextImages,
                  url,
                  _id,
                  like,
                  dislike,
                  publishedAt,
                  category,
                }}
              />
            </>
          );
        }}
      </ClassNames>
    </LikeDislikeContext.Provider>
  );
};
