import { css } from '@emotion/react';
import { ButtonThemeTypes, ButtonVariantTypes } from './types';

export const ButtonContentStyle = css`
  display: flex;
  justify-content: space-between;
  column-gap: var(--yt-size-m);
  padding: var(--yt-size-m);
  align-items: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrePostIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PrimaryButtonStyle = function (theme: ButtonThemeTypes) {
  switch (theme) {
    case ButtonThemeTypes.Light: {
      return css`
        ${ButtonContentStyle};
        border: none;
        background: var(--yt-neutral-grey-4);
        color: var(--yt-dark);
        &:hover:enabled {
          background: var(--yt-neutral);
        }
      `;
    }
    case ButtonThemeTypes.Dark: {
      return css`
        ${ButtonContentStyle};
        border: none;
        background: var(--yt-dark);
        color: var(--yt-neutral-grey-3);
        &:hover:enabled {
          background: var(--yt-neutral-grey-2);
        }
      `;
    }
    default: {
      return css`
        ${ButtonContentStyle};
        border: none;
        background: var(--yt-neutral-grey-3);
        color: var(--yt-dark);
        &:hover:enabled {
          opacity: var(--yt-neutral-grey-4);
        }
      `;
    }
  }
};

export const SecondaryButtonStyle = function (theme: ButtonThemeTypes) {
  switch (theme) {
    case ButtonThemeTypes.Light: {
      return css`
        ${ButtonContentStyle};
        border: var(--yt-size-s) solid var(--yt-neutral-grey-3);
        background: none;
        color: var(--yt-dark);
        &:hover:enabled {
          background: var(--yt-neutral-grey-4);
        }
      `;
    }
    default: {
      return css`
        ${ButtonContentStyle};
        border: var(--yt-size-s) solid var(--yt-neutral-grey-3);
        background: none;
        color: var(--yt-dark);
        &:hover:enabled {
          background: var(--yt-neutral-grey-4);
        }
      `;
    }
  }
};

export const ButtonBaseStyle = function (
  variant: ButtonVariantTypes,
  theme: ButtonThemeTypes
) {
  switch (variant) {
    case ButtonVariantTypes.Primary: {
      return PrimaryButtonStyle(theme);
    }
    case ButtonVariantTypes.Secondary: {
      return SecondaryButtonStyle(theme);
    }
    default: {
      return PrimaryButtonStyle(theme);
    }
  }
};
