import { css } from '@emotion/react';

export const SidebarActions = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: var(--yt-size-l);
  margin: calc(-1 * var(--yt-size-3xl));
`;

export const SidebarAction = css`
  display: flex;
  align-items: center;
  margin: var(--yt-size-ml);
  cursor: pointer;
`;

export const SidebarCloseActionContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--yt-size-3xl);
  cursor: pointer;
`;

export const SidebarCloseAction = css`
  width: var(--yt-size-6xl);
  height: var(--yt-size-6xl);
  margin-top: var(--yt-size-l);
  margin-right: var(--yt-size-l);
  border-radius: var(--yt-size-3xl);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  &:hover,
  &:focus {
    background: var(--yt-neutral-grey-4);
  }
`;

export const SidebarContainer = css`
  width: 75%;
`;

export const Divider = css`
  border-width: 0 0 thin;
  border-style: solid;
  border-color: var(--yt-neutral-grey-4);
`;

export const LinkStyle = css`
  text-decoration: none;
  color: inherit;
`;
