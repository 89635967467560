const AppConstants = {
  login: 'login',
  loginPage: {
    url: 'login',
    header: 'Sign in',
    signInBtnText: 'SIGN IN',
    signInLoading: 'SIGNING...',
    forgotPwd: 'Forgot password?',
    signUpText: "Don't have an account? sign up",
  },
  registerUserPage: {
    header: 'Sign up',
    signUpBtnText: 'SIGN UP',
    signUpLoading: 'SIGNING...',
    alreadyHaveAccount: 'Already have an account? Sign in',
    confirmPwdNotSameAsPwd: '* Password and confirm password must be same',
  },
  passwordChangePage: {
    header: 'Change password',
    confirmPwdNotSameAsPwd: '* Password and confirm password must be same',
    newPwdSameAsOldPwd: '* New password and old password cannot be same',
    passwordChangeBtnText: 'SUBMIT',
    passwordChangeLoadingText: 'SUBMITTING...',
    goBack: 'BACK',
  },
  passwordResetPage: {
    header: 'Reset Password',
    passwordResetBtnText: 'Submit',
    passwordResetLoading: 'Loading',
  },
  passwordResetDonePage: {
    passwordResetEmailSentText:
      'An email with a link to reset password has been sent . Please check your inbox and follow the instruction to reset your password',
  },
  passwordResetConfirmPage: {
    header: 'Enter New Password',
    passwordResetConfirmBtnText: 'Submit',
    passwordResetConfirmLoading: 'Loading',
    confirmPwdNotSameAsPwd: '* Password and confirm password must be same',
  },
  passwordResetCompletePage: {
    passwordResetCompleteText: 'Your password reset is complete. You can now',
    loginClickText: ' click ',
    passwordResetCompleteSecondaryText: 'on this to login',
  },
  signUp: 'sign_up',
  passwordChange: 'password_change',
  passwordReset: 'password_reset',
  passwordResetDone: 'password_reset_done',
  passwordResetConfirm: 'password_reset_confirm/:uid/:token',
  passwordResetComplete: 'password_reset_complete',
  otp: 'otp_form',
  vendor: 'YouTube',
  noResultsFound: 'No results found',
  insightsHubHeaderText: 'Insights Hub',
  termsAndConditionsModal: {
    title: 'Terms and conditions of use',
    subHeader:
      'We request you to read through the terms of use of this site to maintain privacy, security and copy right laws',
    acceptCheckboxLabel: 'I accept the above terms and conditions',
    saveChangesLoading: 'Saving...',
    saveChangesTxt: 'Save changes',
  },
  profileMenu: {
    changePassword: 'Change password',
    logout: 'Logout',
  },
  poweredBy: 'powered by',
  likeNewsText: '{0} people have liked this news',
  dislikeNewsText: '{0} people have disliked this news',
  readMore: 'Read more...',
  articlesAnalysisBtn: 'View articles analysis',
  analysisRequiredCheckBoxLabel: 'Analysis (Select if required)',
  fromDateInputLabel: 'Start Date',
  toDateInputLabel: 'End Date',
  newsModal: {
    viewSource: 'View Source Link',
    summaryPrefix: 'Summary',
    contextImageAlt: 'Context Image',
  },
  insightsLandingTabHeadings: [
    'Latest Trends',
    'Insights For Growth',
    'Industry News and Trends',
    'Earnings Call Summary',
    'Directory',
  ],
  insightsLandingTabUrls: [
    'latest_trends',
    'insights_for_growth',
    'industry_news_trends',
    'earnings_call_summary',
    'directory',
  ],
  viewArticlesAnalysisUrl: 'view_articles_analysis',
  latestTrendsTab: {
    maxDataLimitPerSection: 3,
    defaultNoOfItems: 6,
    defaultNoOfItemsForSmallScreens: 3,
    noResultsDesc: 'No relevant latest trend found',
    // sections: {
    //   insightsForGrowth: {
    //     title: 'Insights For Growth',
    //     description:
    //       'Know the biggest trends and innovation driving insights for growth today',
    //     carousel: {
    //       pageSize: 3,
    //     },
    //     viewMoreBtnText: 'View More',
    //   },
    //   industryTrends: {
    //     noResultsDesc: 'No relevant trend found for the selected range',
    //     title: 'Industry Trends',
    //     description:
    //       'Know how the industry is evolving with the latest on peer watch and market intelligence',
    //     carousel: {
    //       pageSize: 3,
    //     },
    //     viewMoreBtnText: 'View More',
    //   },
    // },
  },
  insightsForGrowthTab: {
    sections: {
      insightsForGrowth: {
        regions: ['Global'],
        selectAll: 'All',
        filters: {
          categories: 'Categories',
          week: 'Week',
        },
        noResultsDesc:
          'No relevant marketing info found for the selected range',
        title: 'Insights For Growth',
        description:
          'Know the biggest trends and innovation driving insights for growth today',
        carousel: {
          pageSize: 6,
        },
      },
    },
  },
  earningsCallSummaryTab: {
    sections: {
      quarterlyPerformance: {
        title: 'Quarterly Performance',
        description: "Key highlights from competitors' quarterly performance",
        competitorLogoAltText: 'Competitor logo',
        cardTitle: `Quarter ending {0} {1}`,
        viewSourceBtnText: 'View Source',
        viewMoreBtnText: 'View More',
        defaultNoOfItems: 2,
        defaultNoOfItemsForSmallScreens: 1,
      },
    },
  },
  industryTrendsAndNewsTab: {
    sections: {
      industryTrends: {
        regions: ['Global'],
        selectAll: 'All',
        filters: {
          categories: 'Categories',
          week: 'Week',
        },
        noResultsDesc: 'No relevant trend found for the selected range',
        title: 'Industry Trends',
        description:
          'Know how the industry is evolving with the latest on peer watch and market intelligence',
        carousel: {
          pageSize: 6,
        },
      },
    },
  },
  directoryTab: {
    sections: {
      directory: {
        title: 'Directory',
        maxNoOfCategoriesLoading: 10,
        description: 'Exhaustive list of all compiled articles',
        insightsForGrowthDirectory: 'Insights For Growth',
        industryNewsAndTrendsDirectory: 'Industry News and Trends',
      },
    },
  },
};

export { AppConstants };
