import { gql } from '../__generated__';

export const LIKE_TREND = gql(/* GraphQL */ `
  mutation LikeNewsAndTrends($input: LikeNewsInput) {
    likeNews(input: $input) {
      likeCount
    }
  }
`);

export const DISLIKE_TREND = gql(/* GraphQL */ `
  mutation DislikeNewsAndTrends($input: DislikeNewsInput) {
    dislikeNews(input: $input) {
      dislikeCount
      _id
    }
  }
`);

export const LOGIN_USER = gql(/* GraphQL */ `
  mutation LoginUser($input: LoginInput) {
    loginUser(input: $input) {
      access
      user {
        username
        email
      }
    }
  }
`);

export const LOGOUT_USER = gql(/* GraphQL */ `
  mutation LogoutUser {
    logoutUser {
      message
    }
  }
`);

export const REGISTER_USER = gql(/* GraphQL */ `
  mutation RegisterUser($input: RegisterUserInput) {
    registerUser(input: $input) {
      message
    }
  }
`);

export const GET_ACCESS_TOKEN = gql(/* GraphQL */ `
  mutation GetAccessTokenFromRefreshToken {
    getAccessTokenFromRefreshToken {
      access
      user {
        username
        email
      }
    }
  }
`);

export const CHANGE_PASSWORD = gql(/* GraphQL */ `
  mutation ChangePassword($input: ChangeUserPwdInput) {
    changeUserPwd(input: $input) {
      message
    }
  }
`);

export const PASSWORD_RESET = gql(/* GraphQL */ `
  mutation PasswordReset($input: PasswordResetInput) {
    passwordReset(input: $input) {
      message
    }
  }
`);

export const PASSWORD_RESET_CONFIRM = gql(/* GraphQL */ `
  mutation PasswordResetConfirm($input: PasswordResetConfirmInput) {
    passwordResetConfirm(input: $input) {
      message
    }
  }
`);

export const ACCEPT_TERMS_AND_CONDITIONS = gql(/* GraphQL */ `
  mutation acceptTermsAndConditions($input: AcceptTermsAndConditionsInput) {
    acceptTermsAndConditions(input: $input) {
      success
    }
  }
`);
