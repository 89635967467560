/** @jsxImportSource @emotion/react */
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { ClassNames } from '@emotion/react';
import { NewsModalProps } from './types';
import { Button, Card, Popup } from '../../common';
import {
  ButtonThemeTypes,
  ButtonVariantTypes,
} from '../../common/Button/types';
import { NewsActionsToolbar } from './NewsActionsToolbar';
import { AppConstants } from '../../../constants';
import { breakpoints } from '../../../breakpoints';
import {
  Category,
  NewsCardSubTitle,
  NewsModalCardBaseStyle,
  NewsModalCardDescriptionContainer,
  NewsModalCardFooter,
  NewsModalCardSubtitleContainer,
  NewsModalCardTitleContainer,
  NewsModalCategoryAndTitleContainer,
  NewsModalCloseBtnContainer,
  NewsModalContextImage,
  NewsModalContextImagesContainer,
  NewsModalFadeAnimateContainer,
  NewsModalFooter,
  ViewSourceLinkBtn,
} from './styles';

const {
  newsModal: { viewSource, summaryPrefix, contextImageAlt },
} = AppConstants;

export const NewsModal = ({
  open,
  onClose,
  title,
  source,
  relevantContextImages,
  url,
  summary,
  _id,
  publishedAt,
  category,
}: NewsModalProps) => {
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints[1]}px)`);
  const publishedDate = new Date(publishedAt || '');
  const publishedMonth = publishedDate.toLocaleString('default', {
    month: 'long',
  });
  const humanReadblePublishedAt = `${publishedMonth} ${publishedDate.getDate()}, ${publishedDate.getFullYear()}`;

  const handleViewSourceBtnClick = function (url: string) {
    window.open(url, '_blank');
  };

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Popup
          {...{
            open,
            onClose,
            closeAfterTransition: true,
            scroll: 'body',
            fullScreen: isSmallScreen,
            'aria-describedby': 'alert-dialog-slide-description',
          }}
        >
          <div css={NewsModalFadeAnimateContainer}>
            <Card
              {...{
                cardTitle: (
                  <div css={NewsModalCategoryAndTitleContainer}>
                    <div css={NewsModalCardTitleContainer}>
                      <div css={Category}>{category}</div>
                      <span onClick={onClose} css={NewsModalCloseBtnContainer}>
                        &times;
                      </span>
                    </div>
                    <div>{title}</div>
                  </div>
                ),
                cardSubTitle: (
                  <div
                    className={cx(
                      css`
                        ${NewsCardSubTitle}
                      `,
                      css`
                        ${NewsModalCardSubtitleContainer}
                      `
                    )}
                  >
                    {source?.name}, {humanReadblePublishedAt}
                  </div>
                ),
                cardDescription: (
                  <div
                    className={css`
                      ${NewsModalCardDescriptionContainer}
                    `}
                  >
                    <span>{summaryPrefix}: </span>
                    {summary}
                    <ul
                      className={css`
                        ${NewsModalContextImagesContainer}
                      `}
                    >
                      {relevantContextImages?.map((image, idx) => (
                        <li key={`${image}-${idx}`}>
                          <img
                            src={image || ''}
                            alt={contextImageAlt}
                            css={NewsModalContextImage}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ),
                cardFooter: (
                  <div css={NewsModalCardFooter}>
                    {url && (
                      <Button
                        {...{
                          theme: ButtonThemeTypes.Light,
                          variant: ButtonVariantTypes.Secondary,
                          css: ViewSourceLinkBtn,
                          onClick: () => handleViewSourceBtnClick(url),
                        }}
                      >
                        {viewSource}
                      </Button>
                    )}
                    <NewsActionsToolbar
                      {...{
                        _id,
                      }}
                    />
                  </div>
                ),
                className: css`
                  ${NewsModalCardBaseStyle}
                `,
                cardFooterClassName: css`
                  ${NewsModalFooter}
                `,
              }}
            />
          </div>
        </Popup>
      )}
    </ClassNames>
  );
};
