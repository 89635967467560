import { css } from '@emotion/react';
import { mq } from '../../../breakpoints';

export const CarouselContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 'var(--yt-size-xl)',
});

export const ArrowContainer = (enabled: boolean) => css`
  display: none;
  height: var(--yt-size-50xl);
  ${mq[2]} {
    display: block;
  }
  span {
    position: sticky;
    top: 50%;
    border-radius: calc(var(--yt-size-9xl) + 2 * var(--yt-size-s));
    background: ${enabled ? 'var(--yt-neutral-grey-7)' : 'none'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--yt-size-m);
    font-weight: 700;
    width: calc(var(--yt-size-9xl) + 2 * var(--yt-size-s));
    height: calc(var(--yt-size-9xl) + 2 * var(--yt-size-s));
    font-size: var(--yt-size-3xl);
    cursor: pointer;
    box-shadow: ${enabled
      ? '0 var(--yt-size-m) calc(var(--yt-size-l) / 2) calc(var(--yt-size-ml) / 2) rgba(182, 180, 180, 0.25)'
      : 'none'};
    ${enabled ? `&:hover { background: var(--yt-neutral-grey-4);` : ''}
  }
`;

export const GridContainer = css`
  display: grid;
  width: 100%;
  ${mq[0]} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  ${mq[1]} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${mq[2]} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  gap: var(--yt-size-12xl);
  margin: var(--yt-size-3xl);
`;

export const CarouselIndicatorContainer = css`
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${mq[2]} {
    display: flex;
    grid-column: span 3 / span 3;
    gap: var(--yt-size-l);
  }
`;

export const CarouselIndicator = (isActive: boolean) => css`
  border-radius: calc(var(--yt-size-9xl) + 2 * var(--yt-size-s));
  background: ${isActive ? 'var(--yt-dark)' : 'var(--yt-neutral-grey-4)'};
  width: var(--yt-size-l);
  height: var(--yt-size-l);
  cursor: ${isActive ? 'default' : 'pointer'};
`;
