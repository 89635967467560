/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { GET_ARTICLES_ANALYSIS_DOC } from '../../middleware/queries';
import {
  Button,
  Navigation,
  Notification,
  Send,
} from '../../components/common';
import { ButtonThemeTypes } from '../../components/common/Button/types';
import { AppConstants } from '../../constants';
import DatePickerComponent from '../../components/common/DatePicker/DatePicker';
import CheckBox from '../../components/common/CheckBox/CheckBox';
import { Container, LoadingSpinner, ViewBtn } from './styles';

const { articlesAnalysisBtn, analysisRequiredCheckBoxLabel } = AppConstants;

export const ArticlesAnalysis = () => {
  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false);
  const [getEcsSummaryDoc, { loading, error, data, networkStatus }] =
    useLazyQuery(GET_ARTICLES_ANALYSIS_DOC);

  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [isAnalysis, setIsAnalysis] = useState<boolean>(false);

  const formattedDate = (inputDate: Date) =>
    `${(inputDate.getMonth() + 1).toString().padStart(2, '0')}-${inputDate
      .getDate()
      .toString()
      .padStart(2, '0')}-${inputDate.getFullYear()}`;

  const handleFromDateChange = (value: any) => {
    setFromDate(formattedDate(value['$d']));
  };

  const handleToDateChange = (value: any) => {
    setToDate(formattedDate(value['$d']));
  };

  useEffect(() => {
    error?.message && setOpenErrorAlert(true);
  }, [error]);

  useEffect(() => {
    const contentBase64 = data?.viewArticlesAnalysis?.analysisXlsBase64Str;

    if (contentBase64 && networkStatus !== NetworkStatus.loading) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${contentBase64}`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = linkSource;
      downloadLink.target = '_self';
      downloadLink.download = 'output_results.xlsx';
      downloadLink.click();
    }
  }, [data, networkStatus]);

  const handleViewSourceBtnClick = function () {
    getEcsSummaryDoc({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          fromDate,
          toDate,
          analysis: isAnalysis,
        },
      },
    });
  };

  const handleErrorAlertClose = function () {
    setOpenErrorAlert(false);
  };

  const handleAnalysisCheckboxChange = () => {
    setIsAnalysis(!isAnalysis);
  };

  if (error) {
    return (
      <Notification
        {...{
          open: openErrorAlert,
          onClose: handleErrorAlertClose,
          type: 'error',
        }}
      >
        {error.message}
      </Notification>
    );
  }

  return (
    <div css={Container}>
      <Navigation />
      <DatePickerComponent
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={handleFromDateChange}
        onToDateChange={handleToDateChange}
      />

      <CheckBox
        checked={isAnalysis}
        onCheckBoxChange={handleAnalysisCheckboxChange}
        label={analysisRequiredCheckBoxLabel}
      />

      <Button
        theme={ButtonThemeTypes.Light}
        disabled={loading}
        iconPre={
          loading ? (
            <CircularProgress size={15} css={LoadingSpinner} />
          ) : undefined
        }
        onClick={handleViewSourceBtnClick}
        iconPost={!loading ? <Send /> : undefined}
        css={ViewBtn}
      >
        {articlesAnalysisBtn}
      </Button>
    </div>
  );
};
