/** @jsxImportSource @emotion/react */
import React from 'react';
import { AppConstants } from '../../constants';
import { FormContainer } from './styles';

const {
  passwordResetDonePage: { passwordResetEmailSentText },
} = AppConstants;

const PasswordResetDone = () => {
  return (
    <div css={FormContainer}>
      <h3>{passwordResetEmailSentText}</h3>
    </div>
  );
};
export default PasswordResetDone;
