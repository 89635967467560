import { css } from '@emotion/react';
import { MediumTextStyle, XtraSmallTextStyle } from '../../../typography';
import { mq } from '../../../breakpoints';

export const HeaderAndDescription = css({
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 'var(--yt-size-l)',
});

export const QuarterlyPerformanceDesc = css({
  padding: 'var(--yt-size-l)',
});

export const SummaryGrid = css`
  display: grid;
  gap: var(--yt-size-l);
  grid-template-columns: repeat(1, minmax(0, 1fr));
`;

export const SummaryContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const CompetitorImgContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'var(--yt-size-30xl)',
  height: 'calc(var(--yt-size-30xl) / 2)',
});

export const Entity = css`
  ${XtraSmallTextStyle};
  font-weight: 700;
  color: var(--yt-dark);
  margin-top: var(--yt-size-m);
`;

export const ParentList = css({
  paddingLeft: 'var(--yt-size-2xl)',
});

export const ListItem = css({
  paddingLeft: 'var(--yt-size-m)',
});

export const ChildList = css({
  paddingLeft: 'var(--yt-size-m)',
});

export const SummaryCardBaseStyle = (isTruncated: boolean) => css`
  ${isTruncated
    ? `
  border-radius: var(--yt-size-m);  
  width: 100%;
  overflow-y: hidden;
  border: var(--yt-size-s) solid var(--yt-neutral-grey-3);
  & > div {
    padding: var(--yt-size-l);
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0
      calc(-1 * (var(--yt-size-12xl) + var(--yt-size-l) + 2 * var(--yt-size-s)))
      calc(var(--yt-size-30xl) / 2)
      calc(
        -1 * (var(--yt-size-12xl) + var(--yt-size-2xl) + 2 * var(--yt-size-s))
      )
      rgba(0, 0, 0, 0.3) inset;
  }`
    : `
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    background: var(--yt-neutral-grey-7);
    border: none;
    box-shadow: none;
    border-radius: var(--yt-size-ml);
    height: max-content;
    width: 100%;
  `}
`;

export const SummaryCardFooterBaseStyle = css({
  bottom: '5%',
  right: '2%',
});

export const SummaryCardFooterBtnsContainer = css({
  display: 'flex',
  margin: 'var(--yt-size-ml)',
  justifyContent: 'flex-end',
});

export const ViewBtn = css({
  borderRadius: 'var(--yt-size-3xl)',
  padding: 'var(--yt-size-xl)',
});

export const LoaderContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CardImgSkeleton = css({
  height: 'calc(0.7 * var(--yt-size-30xl))',
});

export const EcsCardImage = css`
  border-radius: var(--yt-size-ml);
`;

export const SkeletonCardTitleContainer = css({
  marginTop: 'var(--yt-size-m)',
  padding: 'var(--yt-size-m) var(--yt-size-m) 0 var(--yt-size-m)',
});

export const SkeletonCardTitle = css({
  height: 'var(--yt-size-3xl)',
});

export const SkeletonCardSubtitleContainer = css({
  padding: 'var(--yt-size-s) var(--yt-size-m) 0 var(--yt-size-m)',
});

export const SkeletonCardSubtitle = css({
  height: 'var(--yt-size-3xl)',
  width: '40%',
});

export const SkeletonCardBaseStyle = css`
  height: calc(1.5 * var(--yt-size-30xl));
  border: none;
  box-shadow: none;
  width: 90%;
`;

export const SkeletonCardFooterContainer = css({
  position: 'relative',
  marginBottom: 'var(--yt-size-xl)',
});

export const AlertContainer = css({
  width: '100%',
});

export const EcsCardAnimateContainer = css`
  height: auto;
  width: 100%;
`;

export const SummaryModalFadeAnimateContainer = css({
  overflowY: 'auto',
  maxHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const SummaryModalCategoryAndTitleContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
});

export const SummaryModalCardTitleContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const SummaryModalCloseBtnContainer = css({
  fontSize: 'var(--yt-size-3xl)',
  color: 'var(--yt-dark)',
  cursor: 'pointer',
});

export const LoadingSpinner = css`
  color: var(--yt-dark);
`;

export const EcsAllSummariesContainer = css`
  display: flex;
  flex-direction: column;
  column-gap: var(--yt-size-xl);
  margin-left: var(--yt-size-xl);
  margin-right: var(--yt-size-xl);
`;

export const EcsCategoryContainer = css`
  overflow-x: auto;
  display: flex;
  gap: var(--yt-size-l);
  ${mq[2]} {
    width: auto;
    justify-content: space-between;
  }
`;

export const CategoryButtons = css`
  border-radius: var(--yt-size-xl);
  white-space: nowrap;
  margin: var(--yt-size-l);
  padding: var(--yt-size-m);
  ${mq[2]} {
    padding: var(--yt-size-l);
  }
`;

export const EcsAccordians = css`
  width: 100%;
  margin-top: var(--yt-size-4xl);
  margin-bottom: var(--yt-size-4xl);
  box-shadow: 0 var(--yt-size-m) calc(var(--yt-size-l) / 2)
    calc(var(--yt-size-ml) / 2) rgba(182, 180, 180, 0.25);
  &.Mui-expanded {
    margin: var(--yt-size-4xl) 0;
  }
  &.Mui-expanded:first-of-type {
    margin-top: var(--yt-size-4xl);
  }
  &.Mui-expanded:last-of-type {
    margin-bottom: var(--yt-size-4xl);
  }
`;

export const SummaryCardTitleStyle = css`
  color: var(--yt-dark);
  display: block;
  ${MediumTextStyle}
`;
