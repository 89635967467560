import { css } from '@emotion/react';
import { SmallTextStyle, XtraSmallTextStyle } from '../../../typography';

export const NewsCardImage = css`
  border-radius: var(--yt-size-ml);
`;

export const NewsCardTitle = css({
  marginTop: 'var(--yt-size-ml)',
  padding: 'var(--yt-size-ml)',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 'var(--yt-size-ml)',
});

export const NewsCardSubTitle = css({
  paddingRight: 'var(--yt-size-ml)',
  paddingLeft: 'var(--yt-size-ml)',
  color: 'var(--yt-neutral)',
});

export const NewsCardDescriptionContainer = css({
  padding: 'var(--yt-size-ml)',
  marginTop: 'var(--yt-size-xl)',
});

export const ReadMoreLink = css`
  ${XtraSmallTextStyle};
  display: block;
  text-decoration: none;
  margin-top: var(--yt-size-l);
  color: var(--yt-link);
  z-index: 2;
  position: relative;
  width: max-content;
`;

export const LoadingSpinner = css`
  color: var(--yt-red);
`;

export const NewsCardBaseStyle = css({
  height: '100%',
  width: '100%',
  borderRadius: 'var(--yt-size-ml)',
  borderTop: 'none',
  border: 'var(--yt-size-s) solid var(--yt-neutral-grey-3)',
  cursor: 'pointer',
});

export const NewsCardContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const AlertContainer = css({
  width: '100%',
});

export const LoaderContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CardImgSkeleton = css({
  height: 'calc(0.7 * var(--yt-size-30xl))',
});

export const SkeletonCardBaseStyle = css({
  height: 'calc(1.5 * var(--yt-size-30xl))',
  width: '100%',
  borderRadius: 'var(--yt-size-ml)',
  borderTop: 'none',
  border: 'var(--yt-size-s) solid var(--yt-neutral-grey-3)',
});

export const SkeletonCardFooterContainer = css({
  position: 'relative',
  marginBottom: 'var(--yt-size-xl)',
});

export const SkeletonCardTitleContainer = css({
  marginTop: 'var(--yt-size-m)',
  padding: 'var(--yt-size-m) var(--yt-size-m) 0 var(--yt-size-m)',
});

export const SkeletonCardTitle = css({
  height: 'var(--yt-size-3xl)',
});

export const SkeletonCardSubtitleContainer = css({
  padding: 'var(--yt-size-s) var(--yt-size-m) 0 var(--yt-size-m)',
});

export const SkeletonCardSubtitle = css({
  height: 'var(--yt-size-3xl)',
  width: '50%',
});

export const NewsCardGrowAnimateContainer = css({
  height: '100%',
  width: '100%',
});

export const NewsModalFadeAnimateContainer = css({
  overflowY: 'auto',
  maxHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NewsModalCardTitleContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const NewsModalCategoryAndTitleContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  rowGap: 'var(--yt-size-ml)',
  flexDirection: 'column',
});

export const NewsModalCloseBtnContainer = css({
  fontSize: 'var(--yt-size-3xl)',
  color: 'var(--yt-dark)',
  cursor: 'pointer',
});

export const NewsModalCardSubtitleContainer = css({
  padding: '0',
});

export const NewsModalCardDescriptionContainer = css`
  margin-top: var(--yt-size-xl);
  span {
    ${SmallTextStyle};
    font-weight: 700;}
  }
`;

export const NewsModalCardBaseStyle = css`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  background: var(--yt-neutral-grey-7);
  border: none;
  box-shadow: none;
  padding: var(--yt-size-ml);
  border-radius: var(--yt-size-ml);
  height: max-content;
  width: 100%;
`;

export const NewsModalFooter = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: var(--yt-size-2xl);
  margin-bottom: var(--yt-size-m);
`;

export const ViewSourceLinkBtn = css`
  ${XtraSmallTextStyle};
  border-radius: var(--yt-size-m);
`;

export const NewsModalCardFooter = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

export const NewsModalContextImagesContainer = css`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: column;
  row-gap: var(--yt-size-2xl);
  margin-top: var(--yt-size-l);
  list-style: none;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NewsModalContextImage = css({
  width: 'var(--yt-size-50xl)',
  height: 'var(--yt-size-30xl)',
  maxWidth: '100%',
  maxHeight: '100%',
});

export const Category = css`
  ${XtraSmallTextStyle};
  color: var(--yt-categorical-palette-green);
`;

export const LikeDislikeIconBtn = css`
  border-radius: var(--yt-size-m);
  height: max-content;
  button {
    background: var(--yt-neutral-grey-7);
    padding: var(--yt-size-ml);
  }
  box-shadow: 0 calc(2 * var(--yt-size-s)) var(--yt-size-ml) 0
    rgba(0, 0, 0, 0.15);
  button:hover:enabled {
    background: var(--yt-neutral-grey-7);
    path {
      fill: var(--yt-dark);
    }
  }
`;

export const Container = css({
  display: 'flex',
  columnGap: 'var(--yt-size-ml)',
});

export const NewsCardFooterBaseStyle = css({
  bottom: '5%',
  right: '2%',
});
