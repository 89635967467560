import { css } from '@emotion/react';
import { MediumTextStyle } from '../../typography';

export const FormContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  margin: var(--yt-size-xl);
  & > form {
    max-width: var(--yt-size-90xl);
  }
`;

export const SignInOrRegisterBtn = css`
  ${MediumTextStyle};
  background-color: var(--yt-red);
  color: var(--yt-white);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--yt-size-l);
  border-radius: var(--yt-size-m);
  margin-top: var(--yt-size-xl);
  &:disabled {
    background-color: var(--yt-neutral);
  }
`;

export const GoBackBtn = css`
  ${MediumTextStyle};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--yt-size-l);
  border-radius: var(--yt-size-m);
  margin-top: var(--yt-size-xl);
  &:disabled {
    background-color: var(--yt-neutral);
  }
`;

export const FooterContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: var(--yt-size-xl);
  flex-wrap: wrap;
`;

export const FooterLink = css`
  color: var(--yt-red);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const PageLoadProgress = css`
  background-color: var(--yt-neutral);
  & .MuiLinearProgress-colorPrimary {
    background-color: var(--yt-red);
  }
  & .MuiLinearProgress-barColorPrimary {
    background-color: var(--yt-red);
  }
`;

export const SignInOrRegisterProgress = css`
  color: var(--yt-neu);
`;
