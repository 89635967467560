/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {
  EarningCallSummary,
  IndustryTrendsNews,
  LatestTrends,
  SourceDirectory,
  GrowthMarketing,
} from '../../components/InsightsLandingComponents';
import { Tabs, Navigation } from '../../components/common';
import { AppConstants } from '../../constants';
import { InsightsLandingProps } from './types';
import { breakpoints } from '../../breakpoints';

const { insightsLandingTabHeadings, insightsLandingTabUrls } = AppConstants;

export const InsightsLanding = ({
  selectedTabIdx = 0,
}: InsightsLandingProps) => {
  const isMediumScreen = useMediaQuery(`(min-width: ${breakpoints[1]}px)`);
  const navigate = useNavigate();

  const handleOnSelectTab = function (currentIdx: number) {
    navigate(`/${insightsLandingTabUrls[currentIdx]}`, {
      replace: true,
    });
  };

  const tabs = useMemo(
    () => [
      <LatestTrends key="one" />,
      <GrowthMarketing key="two" />,
      <IndustryTrendsNews key="three" />,
      <EarningCallSummary key="four" />,
      <SourceDirectory key="five" />,
    ],
    []
  );

  return (
    <>
      <Navigation />
      {isMediumScreen ? (
        <Tabs
          {...{
            tabHeadings: insightsLandingTabHeadings,
            tabContents: tabs,
            selectedTabIndex: selectedTabIdx,
            onSelectTab: handleOnSelectTab,
          }}
        />
      ) : (
        tabs[selectedTabIdx]
      )}
    </>
  );
};
