// DatePickerComponent.tsx
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClassNames } from '@emotion/react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DatePickerComponentProps } from './types';
import { AppConstants } from '../../../constants';
import { DateContainer, DatePickerStyling } from './styles';

const { fromDateInputLabel, toDateInputLabel } = AppConstants;

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  onFromDateChange,
  onToDateChange,
}) => {
  return (
    <ClassNames>
      {({ css }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            {...{
              className: css`
                ${DateContainer}
              `,
            }}
          >
            <DatePicker
              {...{
                className: css`
                  ${DatePickerStyling}
                `,
                label: fromDateInputLabel,
                closeOnSelect: true,
                onAccept: onFromDateChange,
              }}
            />
            <DatePicker
              {...{
                className: css`
                  ${DatePickerStyling}
                `,
                label: toDateInputLabel,
                closeOnSelect: true,
                onAccept: onToDateChange,
              }}
            />
          </div>
        </LocalizationProvider>
      )}
    </ClassNames>
  );
};

export default DatePickerComponent;
