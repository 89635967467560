import React, { useMemo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { PopupProps } from './types';

const TransitionRight = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const TransitionLeft = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const TransitionUp = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDown = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const Popup = (props: PopupProps) => {
  const {
    children,
    direction,
    title,
    titleClassName,
    dividers,
    dialogActions,
    ...restProps
  } = props;

  const Transition = useMemo(() => {
    switch (direction) {
      case 'down':
        return TransitionDown;
      case 'left':
        return TransitionLeft;
      case 'right':
        return TransitionRight;
      case 'up':
        return TransitionUp;
      default:
        return TransitionDown;
    }
  }, [direction]);

  return (
    <Dialog
      {...{
        ...restProps,
        TransitionComponent: Transition,
      }}
    >
      {title && <DialogTitle className={titleClassName}>{title}</DialogTitle>}
      <DialogContent dividers={dividers ?? false}>{children}</DialogContent>
      {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};
