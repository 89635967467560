import { css } from '@emotion/react';
import { SmallTextStyle, XtraSmallTextStyle } from '../../../typography';

export const BaseContainer = css`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  row-gap: var(--yt-size-m);
  align-items: center;
  justify-content: center;
`;

export const NoResultsText = css`
  ${SmallTextStyle};
  font-weight: 700;
`;

export const DescriptionText = css`
  ${XtraSmallTextStyle};
  font-weight: 400;
  color: var(--yt-neutral);
`;

export const NoResultsImg = css`
  width: var(--yt-size-30xl);
  height: var(--yt-size-30xl);
`;
