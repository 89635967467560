/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { AppConstants } from '../../constants';
import { FormContainer, FooterLink } from './styles';

const {
  passwordResetCompletePage: {
    passwordResetCompleteText,
    loginClickText,
    passwordResetCompleteSecondaryText,
  },
} = AppConstants;

const PasswordResetComplete = () => {
  return (
    <div css={FormContainer}>
      <h2>
        {passwordResetCompleteText}
        <Link to="/login" css={FooterLink}>
          {loginClickText}
        </Link>
        {passwordResetCompleteSecondaryText}
      </h2>
    </div>
  );
};
export default PasswordResetComplete;
