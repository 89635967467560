/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';
import { ClassNames } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { Button, Popup } from '../..';
import { TermsAndConditionsProps } from './types';
import CheckBox from '../../CheckBox/CheckBox';
import { ButtonVariantTypes } from '../../Button/types';
import termsAndConditionsData from './TermsAndConditionsData.json';
import { ACCEPT_TERMS_AND_CONDITIONS } from '../../../../middleware/mutations';
import { YtAuthContext } from '../../../../auth-context/context';
import { AppConstants } from '../../../../constants';
import {
  AcceptCheckbox,
  ErrorContainer,
  ListStyle,
  LoadingSpinner,
  SaveBtn,
  TermsAndConditionsContainer,
} from './styles';

const {
  termsAndConditionsModal: {
    acceptCheckboxLabel,
    saveChangesLoading,
    saveChangesTxt,
    subHeader,
    title,
  },
} = AppConstants;

const TermsAndConditionsModal = ({
  open,
  onClose,
}: TermsAndConditionsProps) => {
  const [isChecked, setChecked] = useState<boolean>(false);
  const {
    userDetails: { username },
  } = useContext(YtAuthContext);
  const [shouldShowError, setShowError] = useState<boolean>(false);
  const [acceptTermsAndConditions, { loading, error }] = useMutation(
    ACCEPT_TERMS_AND_CONDITIONS,
    {
      onCompleted: ({ acceptTermsAndConditions }) => {
        acceptTermsAndConditions?.success && onClose?.();
      },
      onError: () => {
        setShowError(true);
      },
    }
  );

  const handleSaveChanges = function () {
    username &&
      acceptTermsAndConditions({
        variables: {
          input: {
            username,
          },
        },
      });
  };

  const handleCheckboxChange = function () {
    setChecked((currChecked) => !currChecked);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <Popup
          {...{
            open,
            title,
            closeAfterTransition: true,
            dividers: true,
            dialogActions: isChecked ? (
              <Button
                css={SaveBtn}
                disabled={loading}
                iconPre={
                  loading && <CircularProgress size={15} css={LoadingSpinner} />
                }
                variant={ButtonVariantTypes.Secondary}
                onClick={handleSaveChanges}
              >
                {loading ? saveChangesLoading : saveChangesTxt}
              </Button>
            ) : undefined,
            scroll: 'body',
            'aria-describedby': 'alert-dialog-slide-description',
          }}
        >
          <div css={TermsAndConditionsContainer}>
            <p>{subHeader}</p>
            <ol css={ListStyle}>
              {termsAndConditionsData.map((term) => (
                <li key={term}>{term}</li>
              ))}
            </ol>
            <CheckBox
              checked={isChecked}
              onCheckBoxChange={handleCheckboxChange}
              checkBoxLabelClassName={css`
                ${AcceptCheckbox};
              `}
              label={acceptCheckboxLabel}
            />
            {shouldShowError && <p css={ErrorContainer}>{error?.message}</p>}
          </div>
        </Popup>
      )}
    </ClassNames>
  );
};

export default TermsAndConditionsModal;
