import { css } from '@emotion/react';
import { mq } from '../../../breakpoints';
import { SmallTextStyle, XtraSmallTextStyle } from '../../../typography';

export const HeaderAndDescription = css({
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'none',
  marginTop: 'var(--yt-size-l)',
});

export const SourceDirectoryDesc = css({
  padding: 'var(--yt-size-l)',
});

export const AlertContainer = css({
  width: '100%',
});

export const SourceDirectoryContainer = css`
  display: grid;
  width: 100%;
  ${mq[0]} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  ${mq[1]} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${mq[2]} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  gap: var(--yt-size-5xl);
  margin: var(--yt-size-3xl);
  ol li::marker {
    ${XtraSmallTextStyle};
    color: var(--yt-link);
  }
  ol {
    padding-left: var(--yt-size-3xl);
    li {
      padding-left: var(--yt-size-m);
    }
    li a {
      max-width: var(--yt-size-30xl);
      position: relative;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const SourceUrl = css`
  ${XtraSmallTextStyle};
  display: block;
  text-decoration: none;
  margin-top: var(--yt-size-m);
  color: var(--yt-link);
  z-index: 2;
  position: relative;
  width: max-content;
  &:hover {
    text-decoration: underline;
  }
`;

export const SourceName = css`
  ${SmallTextStyle};
  font-weight: 700;
`;

export const CategoryNameLoading = css({
  width: 'calc(0.5 * var(--yt-size-30xl))',
});

export const SourcesListLoading = css({
  listStyle: 'none',
  width: '50%',
});

export const SourceItem = css({
  height: 'var(--yt-size-l)',
  marginTop: 'var(--yt-size-m)',
});

export const TitleContainer = css({
  margin: 'var(--yt-size-xl)',
});
