/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { ClassNames } from '@emotion/react';
import { Button, Notification } from '../common';
import { ButtonVariantTypes } from '../common/Button/types';
import { AppConstants } from '../../constants';
import { PASSWORD_RESET_CONFIRM } from '../../middleware/mutations';
import { PasswordResetConfirmData } from './types';
import {
  FormContainer,
  SignInOrRegisterBtn,
  SignInOrRegisterProgress,
} from './styles';

const {
  login,
  passwordResetConfirmPage: {
    header,
    passwordResetConfirmBtnText,
    confirmPwdNotSameAsPwd,
    passwordResetConfirmLoading,
  },
} = AppConstants;

const PasswordResetConfirm = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [passwordResetConfirmFormData, setPasswordResetConfirmFormData] =
    useState<PasswordResetConfirmData>({
      confirmPassword: '',
      uid: uid || '',
      password: '',
      token: token || '',
    });

  const [doPasswordResetConfirm, { loading, error }] = useMutation(
    PASSWORD_RESET_CONFIRM,
    {
      onError: () => null,
      onCompleted: ({ passwordResetConfirm }) => {
        navigate(`/${login}`, {
          replace: true,
          state: {
            passwordResetConfirmSuccessful: passwordResetConfirm?.message,
          },
        });
      },
    }
  );

  const handleFieldValueChange = function (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof PasswordResetConfirmData
  ) {
    setPasswordResetConfirmFormData((currentDta) => ({
      ...currentDta,
      [`${fieldName}`]: event.target.value,
    }));
  };

  const handleSubmit = async function (
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    if (
      passwordResetConfirmFormData.password !==
      passwordResetConfirmFormData.confirmPassword
    ) {
      return;
    }
    doPasswordResetConfirm({
      variables: {
        input: {
          uid: passwordResetConfirmFormData.uid,
          token: passwordResetConfirmFormData.token,
          confirmPassword: passwordResetConfirmFormData.confirmPassword,
          password: passwordResetConfirmFormData.password,
        },
      },
    });
  };

  const pwdNotEqualsCnfPwdText =
    passwordResetConfirmFormData.confirmPassword !==
      passwordResetConfirmFormData.password && confirmPwdNotSameAsPwd;

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <div css={FormContainer}>
            <h2>{header}</h2>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                value={passwordResetConfirmFormData.password}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Create New Password"
                type="password"
                id="password"
                autoComplete="current-password"
                color="error"
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleFieldValueChange(event, 'password')}
              />
              <TextField
                value={passwordResetConfirmFormData.confirmPassword}
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New password"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                color="error"
                helperText={pwdNotEqualsCnfPwdText}
                error={!!pwdNotEqualsCnfPwdText}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleFieldValueChange(event, 'confirmPassword')}
              />
              <Button
                type="submit"
                variant={ButtonVariantTypes.Primary}
                iconPre={
                  loading && (
                    <CircularProgress
                      size={15}
                      css={SignInOrRegisterProgress}
                    />
                  )
                }
                disabled={loading}
                className={css`
                  ${SignInOrRegisterBtn}
                `}
              >
                {loading
                  ? passwordResetConfirmLoading
                  : passwordResetConfirmBtnText}
              </Button>
            </Box>
          </div>
          {!!error?.message && (
            <Notification
              {...{
                open: !!error.message,
                type: 'error',
              }}
            >
              {error.message}
            </Notification>
          )}
        </>
      )}
    </ClassNames>
  );
};
export default PasswordResetConfirm;
