/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/material';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { AppConstants } from '../../../constants';
import { Button, Send, Notification, ArrowDown } from '../../common';
import { ButtonThemeTypes } from '../../common/Button/types';
import { SummaryCardProps } from './types';
import { GET_ECS_SUMMARY_DOC } from '../../../middleware/queries';
import {
  ChildList,
  CompetitorImgContainer,
  EcsAccordians,
  Entity,
  ListItem,
  LoadingSpinner,
  ParentList,
  SummaryCardFooterBtnsContainer,
  SummaryCardTitleStyle,
  ViewBtn,
} from './styles';

const {
  earningsCallSummaryTab: {
    sections: { quarterlyPerformance },
  },
} = AppConstants;

const { cardTitle, viewSourceBtnText } = quarterlyPerformance;

export const SummaryCard = ({
  publishedQuarter,
  publishedYear,
  summary,
  filepath,
  selectedEntity,
  competitorImgSrc,
  competitorLogoAltText,
  isTruncated = true,
}: SummaryCardProps) => {
  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false);
  const [getEcsSummaryDoc, { loading, error, data, networkStatus }] =
    useLazyQuery(GET_ECS_SUMMARY_DOC);

  useEffect(() => {
    error?.message && setOpenErrorAlert(true);
  }, [error]);

  useEffect(() => {
    const contentBase64 = data?.viewEcsSummary?.base64Str;
    if (contentBase64 && networkStatus !== NetworkStatus.loading) {
      const linkSource = `data:application/pdf;base64,${contentBase64}`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);

      downloadLink.href = linkSource;
      downloadLink.target = '_self';
      downloadLink.download = `${filepath
        ?.split('/')
        [filepath?.split('/').length - 1].split('.')[0]}.pdf`;
      downloadLink.click();
    }
  }, [data, networkStatus]);

  const handleViewSourceBtnClick = function () {
    getEcsSummaryDoc({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          gcsFilePath: filepath,
        },
      },
    });
  };

  const handleErrorAlertClose = function () {
    setOpenErrorAlert(false);
  };

  return (
    <>
      {error && isTruncated && (
        <Notification
          {...{
            open: openErrorAlert,
            onClose: handleErrorAlertClose,
            type: 'error',
          }}
        >
          {error.message}
        </Notification>
      )}
      <Accordion css={EcsAccordians}>
        <AccordionSummary expandIcon={<ArrowDown />}>
          <img
            src={competitorImgSrc}
            alt={competitorLogoAltText}
            css={CompetitorImgContainer}
          />
        </AccordionSummary>
        <AccordionDetails>
          <>
            <header css={SummaryCardTitleStyle}>
              {cardTitle
                .replace('{0}', publishedQuarter)
                .replace('{1}', `${publishedYear}`)}
            </header>
            <ul css={ParentList}>
              {summary
                ?.find(({ entity }) => selectedEntity === entity)
                ?.values?.map((val, idx) =>
                  val?.subEntity ? (
                    <li key={`${val?.subEntity}-${idx}`} css={ListItem}>
                      <p css={Entity}>{val?.subEntity}</p>
                      <ul css={ChildList}>
                        {val?.subEntityValues?.map((val) => (
                          <li key={val} css={ListItem}>
                            {val}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    val?.subEntityValues?.map((val, idx) => (
                      <li key={`${val}-${idx}`} css={ListItem}>
                        {val}
                      </li>
                    ))
                  )
                )}
            </ul>
            <div css={SummaryCardFooterBtnsContainer}>
              <Button
                theme={ButtonThemeTypes.Light}
                disabled={loading}
                iconPre={
                  loading ? (
                    <CircularProgress size={15} css={LoadingSpinner} />
                  ) : undefined
                }
                iconPost={!loading ? <Send /> : undefined}
                css={ViewBtn}
                onClick={handleViewSourceBtnClick}
              >
                {viewSourceBtnText}
              </Button>
            </div>
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
