import { css } from '@emotion/react';
import { mq } from '../../../breakpoints';
import { VendorHeaderStyle } from '../../../typography';

export const Navbar = css`
  position: sticky;
  top: 0;
  z-index: 10;
  margin: 0;
  padding: var(--yt-size-s);
  ${mq[1]} {
    padding: var(--yt-size-6xl);
  }
  box-shadow: 0 var(--yt-size-s) calc(2 * var(--yt-size-s)) 0 rgba(0, 0, 0, 0.1);
  background: var(--yt-neutral-grey-7);
`;

export const Container = css`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: var(--yt-size-l);
  list-style: none;
  flex-direction: column;
  align-items: center;
  ${mq[1]} {
    flex-direction: row;
  }
`;

export const Vendor = css({
  color: 'var(--yt-red)',
});

export const PoweredBy = css`
  display: flex;
  align-items: center;
  width: calc(3 * var(--yt-size-12xl));
  height: var(--yt-size-xl);
  column-gap: var(--yt-size-m);
  padding-top: var(--yt-size-m);
`;

export const AppNameContainer = css`
  ${VendorHeaderStyle};
  margin-top: var(--yt-size-l);
  display: flex;
  align-items: center;
  column-gap: var(--yt-size-l);
  ${mq[1]} {
    position: absolute;
    margin-top: 0;
    column-gap: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    & > div {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const AppMenu = css`
  cursor: pointer;
`;

export const AvatarIcon = css`
  max-width: var(--yt-size-4xl);
  max-height: var(--yt-size-4xl);
`;

export const Divider = css`
  border-width: 0 0 thin;
  border-style: solid;
  border-color: var(--yt-neutral-grey-4);
`;
