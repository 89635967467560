/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { ClassNames } from '@emotion/react';
import { Button, Notification } from '../common';
import { ButtonVariantTypes } from '../common/Button/types';
import { AppConstants } from '../../constants';
import { PASSWORD_RESET } from '../../middleware/mutations';
import { PasswordResetData } from './types';
import {
  FormContainer,
  SignInOrRegisterBtn,
  SignInOrRegisterProgress,
} from './styles';

const {
  login,
  passwordResetPage: { header, passwordResetBtnText, passwordResetLoading },
} = AppConstants;

const PasswordReset = () => {
  const navigate = useNavigate();
  const [passwordResetFormData, setPasswordResetFormData] =
    useState<PasswordResetData>({
      email: '',
    });

  const [doPasswordReset, { loading, error }] = useMutation(PASSWORD_RESET, {
    onError: () => null,
    onCompleted: ({ passwordReset }) => {
      navigate(`/${login}`, {
        replace: true,
        state: {
          passwordResetSuccessful: passwordReset?.message,
        },
      });
    },
  });

  const handleFieldValueChange = function (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof PasswordResetData
  ) {
    setPasswordResetFormData((currentDta) => ({
      ...currentDta,
      [`${fieldName}`]: event.target.value,
    }));
  };

  const handleSubmit = async function (
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    doPasswordReset({
      variables: {
        input: {
          email: passwordResetFormData.email,
        },
      },
    });
  };

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <div css={FormContainer}>
            <h2>{header}</h2>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                value={passwordResetFormData.email}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                color="error"
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleFieldValueChange(event, 'email')}
              />
              <Button
                type="submit"
                variant={ButtonVariantTypes.Primary}
                iconPre={
                  loading && (
                    <CircularProgress
                      size={15}
                      css={SignInOrRegisterProgress}
                    />
                  )
                }
                disabled={loading}
                className={css`
                  ${SignInOrRegisterBtn}
                `}
              >
                {loading ? passwordResetLoading : passwordResetBtnText}
              </Button>
            </Box>
          </div>
          {!!error?.message && (
            <Notification
              {...{
                open: !!error.message,
                type: 'error',
              }}
            >
              {error.message}
            </Notification>
          )}
        </>
      )}
    </ClassNames>
  );
};
export default PasswordReset;
