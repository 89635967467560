/** @jsxImportSource @emotion/react */
import React from 'react';
import { AppConstants } from '../../../constants';
import { LargeTextStyle, SmallTextStyle } from '../../../typography';
import { HeaderAndDescription, SourceDirectoryDesc } from './styles';
import { GrowthMarketingDirectory } from './GrowthMarketingDirectory';
import { IndustryNewsAndTrendsDirectory } from './IndustryNewsTrendsDirectory';

const {
  directoryTab: {
    sections: {
      directory: { description, title },
    },
  },
} = AppConstants;

export const SourceDirectory = () => {
  return (
    <div>
      <div css={[LargeTextStyle, HeaderAndDescription]}>{title}</div>
      <div css={[SmallTextStyle, SourceDirectoryDesc, HeaderAndDescription]}>
        {description}
      </div>
      <GrowthMarketingDirectory />
      <IndustryNewsAndTrendsDirectory />
    </div>
  );
};
