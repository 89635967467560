import { css } from '@emotion/react';

export const DateContainer = css({
  display: 'flex',
  alignItems: 'center',
  margin: 'var(--yt-size-xl)',
});

export const DatePickerStyling = css({
  marginLeft: 'var(--yt-size-ml)',
});
