import { css } from '@emotion/react';

export const LoadingSpinner = css`
  color: var(--yt-dark);
`;

export const ViewBtn = css({
  borderRadius: 'var(--yt-size-3xl)',
  padding: 'var(--yt-size-xl)',
});

export const Container = css`
  & > button {
    margin: var(--yt-size-3xl);
  }
`;
