import { css } from '@emotion/react';
import {
  MediumTextStyle,
  SmallTextStyle,
  XtraSmallTextStyle,
} from '../../../typography';

export const CardBaseStyle = css`
  position: relative;
  width: var(--yt-size-40xl);
  height: var(--yt-size-50xl);
  box-shadow: 0 var(--yt-size-m) calc(var(--yt-size-l) / 2)
    calc(var(--yt-size-ml) / 2) rgba(182, 180, 180, 0.25);
`;

export const CardImgStyle = css({
  width: '100%',
  height: 'var(--yt-size-30xl)',
  display: 'block',
});

export const CardTitleStyle = css`
  color: var(--yt-dark);
  display: block;
  ${MediumTextStyle}
`;

export const CardSubTitleStyle = css`
  display: block;
  ${XtraSmallTextStyle};
`;

export const CardDescriptionStyle = css`
  display: block;
  ${SmallTextStyle};
`;

export const CardFooterStyle = css({
  display: 'flex',
  position: 'absolute',
  bottom: '0',
  right: '0',
  justifyContent: 'flex-end',
  gap: 'var(--yt-size-s)',
  width: '100%',
  height: 'var(--yt-size-2xl)',
});
