import {
  LikeDislikeAction,
  LikeDislikeActionTypes,
  LikeDislikeObj,
} from '../types';

const initialLikeDislikeObj: LikeDislikeObj = {
  dislike: 0,
  like: 0,
};

export const initialiseLikeDislikeObj = function (
  initialValue: LikeDislikeObj
) {
  Object.entries(initialValue).forEach((entry) => {
    initialLikeDislikeObj[entry[0] as keyof LikeDislikeObj] = entry[1];
  });
  return initialLikeDislikeObj;
};

export const likeDislikeReducer = function (
  currentLikeCountObj: LikeDislikeObj,
  action: LikeDislikeAction
): LikeDislikeObj {
  switch (action.type) {
    case LikeDislikeActionTypes.UpdateDislikeCount: {
      return {
        ...currentLikeCountObj,
        dislike: action.dislike,
      };
    }
    case LikeDislikeActionTypes.UpdateLikeCount: {
      return {
        ...currentLikeCountObj,
        like: action.like,
      };
    }
    default: {
      return currentLikeCountObj;
    }
  }
};
