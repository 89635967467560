import { css } from '@emotion/react';

export const SelectBaseStyle = css`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: var(--yt-size-s) solid var(--yt-dark);
    }
    &:hover fieldset {
      border-color: var(--yt-size-s) solid var(--yt-dark);
    }
    &.Mui-focused fieldset {
      border-color: var(--yt-size-s) solid var(--yt-dark);
    }
  }
  & .Mui-disabled {
    cursor: not-allowed !important;
  }
  .MuiInputLabel-root {
    color: var(--yt-neutral) !important;
    .Mui-focused {
      color: var(--yt-neutral) !important;
    }
  }
`;

export const SelectMenuItem = css`
  &.Mui-selected {
    background-color: var(--yt-neutral-grey-4);
    &.Mui-focusVisible {
      background-color: var(--yt-neutral-grey-4);
    }
  }
  &.Mui-selected:hover {
    background-color: var(--yt-neutral-grey-4);
  }
`;
