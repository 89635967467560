/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { SidebarProps } from './types';
import { AppConstants } from '../../../constants';
import { Popup } from '../Popup';
import {
  Divider,
  LinkStyle,
  SidebarAction,
  SidebarActions,
  SidebarCloseAction,
  SidebarCloseActionContainer,
  SidebarContainer,
} from './styles';

const { insightsLandingTabHeadings, insightsLandingTabUrls } = AppConstants;

export const Sidebar = ({ open, onClose }: SidebarProps) => {
  return (
    <Popup
      {...{
        open,
        keepMounted: true,
        closeAfterTransition: true,
        css: SidebarContainer,
        onClose,
        fullScreen: true,
        direction: 'right',
        'aria-describedby': 'alert-dialog-slide-description',
      }}
    >
      <ul css={SidebarActions}>
        <li css={SidebarCloseActionContainer} onClick={onClose}>
          <span css={SidebarCloseAction}>{'<'}</span>
        </li>
        <hr css={Divider} />
        {insightsLandingTabHeadings.map((tabHeading, idx) => (
          <li key={tabHeading} css={SidebarAction}>
            <Link
              to={`/${insightsLandingTabUrls[idx]}`}
              replace
              css={LinkStyle}
              onClick={onClose}
            >
              {tabHeading}
            </Link>
          </li>
        ))}
      </ul>
    </Popup>
  );
};
