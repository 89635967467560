/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ClassNames } from '@emotion/react';
import { Grow, Skeleton, useMediaQuery } from '@mui/material';
import { AppConstants } from '../../../constants';
import { LargeTextStyle, SmallTextStyle } from '../../../typography';
import { GET_EARNING_CALL_SUMMARIES } from '../../../middleware/queries';
import CompetitorsConfig from '../../../config/competitors.config.json';
import { SummaryCard } from './SummaryCard';
import { SummaryType } from '../../../__generated__/graphql';
import { Button, Card, Notification } from '../../common';
import { breakpoints } from '../../../breakpoints';
import { ViewSummaryModal } from './ViewSummaryModal';
import { SummaryCardProps } from './types';
import { ButtonVariantTypes } from '../../common/Button/types';
import {
  CategoryButtons,
  EcsAllSummariesContainer,
  EcsCardAnimateContainer,
  EcsCategoryContainer,
  HeaderAndDescription,
  LoaderContainer,
  QuarterlyPerformanceDesc,
  SkeletonCardBaseStyle,
  SkeletonCardFooterContainer,
  SkeletonCardSubtitle,
  SkeletonCardSubtitleContainer,
  SkeletonCardTitle,
  SkeletonCardTitleContainer,
  SummaryContainer,
  SummaryGrid,
} from './styles';

const {
  earningsCallSummaryTab: {
    sections: { quarterlyPerformance },
  },
} = AppConstants;

const {
  description,
  title,
  competitorLogoAltText,
  defaultNoOfItems,
  defaultNoOfItemsForSmallScreens,
} = quarterlyPerformance;

export const EarningCallSummary = () => {
  const { loading, error, data } = useQuery(GET_EARNING_CALL_SUMMARIES);
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints[1]}px)`);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [summaryModalDetails, setSummaryModalDetails] = useState<
    Pick<SummaryCardProps, 'publishedQuarter' | 'publishedYear' | 'summary'>
  >({
    publishedQuarter: '',
    publishedYear: '',
    summary: [],
  });
  const [shouldOpenSummaryModal, setOpenSummaryModal] =
    useState<boolean>(false);

  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false);

  useEffect(() => {
    error?.message && setOpenErrorAlert(true);
  }, [error]);

  const handleErrorAlertClose = function () {
    setOpenErrorAlert(false);
  };

  const handleSummaryModalClose = function () {
    setOpenSummaryModal(false);
  };

  const handleSummaryModalOpen = function (
    publishedYear: string,
    publishedQuarter: string,
    summary: SummaryType[]
  ) {
    setSummaryModalDetails({
      publishedYear,
      publishedQuarter,
      summary,
    });
    setOpenSummaryModal(true);
  };

  const handleSelectCategory = function (currentCategory: string) {
    setSelectedCategory(currentCategory);
  };

  const categories = useMemo(
    () =>
      data?.earningCallSummaries && data?.earningCallSummaries?.length > 0
        ? [
            ...new Set(
              data?.earningCallSummaries?.flatMap(
                (earningCallSummary) =>
                  earningCallSummary?.summary?.map((summ) => summ?.entity)
              )
            ),
          ]
        : [],
    [data?.earningCallSummaries]
  );

  useEffect(() => {
    if (categories && categories.length > 0) {
      setSelectedCategory(categories[0] as string);
    }
  }, [categories]);

  if (error) {
    return (
      <Notification
        {...{
          open: openErrorAlert,
          onClose: handleErrorAlertClose,
          type: 'error',
        }}
      >
        {error.message}
      </Notification>
    );
  }

  const earningCallSummaries = data?.earningCallSummaries || [];

  const noOfDefaultItems = isSmallScreen
    ? defaultNoOfItemsForSmallScreens
    : defaultNoOfItems;

  return (
    <div>
      <div css={[LargeTextStyle, HeaderAndDescription]}>{title}</div>
      <div
        css={[SmallTextStyle, QuarterlyPerformanceDesc, HeaderAndDescription]}
      >
        {description}
      </div>
      <div css={SummaryGrid}>
        <ClassNames>
          {({ css }) => {
            return (
              <>
                {loading &&
                  Array.from({ length: noOfDefaultItems }).map((_, i) => {
                    return (
                      <div key={i} css={LoaderContainer}>
                        <Card
                          {...{
                            cardTitle: (
                              <div css={SkeletonCardTitleContainer}>
                                <Skeleton
                                  {...{
                                    animation: 'wave',
                                    variant: 'text',
                                    css: SkeletonCardTitle,
                                  }}
                                />
                              </div>
                            ),
                            cardSubTitle: (
                              <div css={SkeletonCardSubtitleContainer}>
                                <Skeleton
                                  {...{
                                    animation: 'wave',
                                    variant: 'text',
                                    css: SkeletonCardSubtitle,
                                  }}
                                />
                                <Skeleton
                                  {...{
                                    animation: 'wave',
                                    variant: 'text',
                                    css: SkeletonCardSubtitle,
                                  }}
                                />
                              </div>
                            ),
                            className: css`
                              ${SkeletonCardBaseStyle}
                            `,
                            cardFooterClassName: css`
                              ${SkeletonCardFooterContainer}
                            `,
                          }}
                        />
                      </div>
                    );
                  })}
              </>
            );
          }}
        </ClassNames>
        {!loading && (
          <div css={EcsAllSummariesContainer}>
            <div css={EcsCategoryContainer}>
              {categories?.map((category) => (
                <Button
                  key={category}
                  variant={
                    selectedCategory === category
                      ? ButtonVariantTypes.Primary
                      : ButtonVariantTypes.Secondary
                  }
                  css={CategoryButtons}
                  onClick={() => handleSelectCategory(category as string)}
                >
                  {category}
                </Button>
              ))}
            </div>
            {earningCallSummaries?.map((earningCallSummary) => {
              const publishedQuarter =
                earningCallSummary?.publishedQuarter as string;
              const publishedYear = earningCallSummary?.publishedYear as string;
              const competitorImgSrc = CompetitorsConfig.find(
                (competitorEntity) =>
                  competitorEntity.competitorName === earningCallSummary?.author
              )?.sourceImg;

              return (
                <div css={SummaryContainer} key={earningCallSummary?.author}>
                  <Grow in timeout={500}>
                    <div css={EcsCardAnimateContainer}>
                      <SummaryCard
                        {...{
                          competitorImgSrc,
                          competitorLogoAltText,
                          publishedQuarter,
                          publishedYear,
                          handleSummaryModalOpen,
                          filepath: earningCallSummary?.filepath as string,
                          selectedEntity: selectedCategory,
                          summary: earningCallSummary?.summary as SummaryType[],
                        }}
                      />
                    </div>
                  </Grow>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <ViewSummaryModal
        {...{
          publishedQuarter: summaryModalDetails.publishedQuarter,
          publishedYear: summaryModalDetails.publishedYear,
          summary: summaryModalDetails.summary as SummaryType[],
          open: shouldOpenSummaryModal,
          onClose: handleSummaryModalClose,
        }}
      />
    </div>
  );
};
