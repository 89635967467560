/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  MenuItem,
  Menu,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { LOGOUT_USER } from '../../../middleware/mutations';
import { YtAuthContext } from '../../../auth-context/context';
import { AppConstants } from '../../../constants';
import { Notification } from '../Notification';
import { LogoutIcon, ChangePasswordIcon } from '../Icons';
import { MediumTextStyle } from '../../../typography';
import { AvatarIcon, Divider } from './styles';

const {
  login,
  passwordChange,
  profileMenu: { logout, changePassword },
} = AppConstants;

export default function Profile() {
  const { userDetails, setAuthAccessToken, setUserDetails } =
    useContext(YtAuthContext);
  const navigate = useNavigate();
  const [doLogout, { loading, error }] = useMutation(LOGOUT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setUserDetails({
        email: '',
        username: '',
      });
      setAuthAccessToken('');
      navigate(`${login}/`, {
        replace: true,
      });
    },
    onError: () => null,
  });
  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  useEffect(() => {
    error?.message && setOpenErrorAlert(true);
  }, [error]);

  const handleErrorAlertClose = function () {
    setOpenErrorAlert(false);
  };

  const handleMenu = function (event: React.MouseEvent<HTMLElement>) {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = function () {
    setMenuAnchor(null);
  };

  const handleLogout = function () {
    doLogout();
    setMenuAnchor(null);
  };

  const handleChangePassword = function () {
    navigate(`/${passwordChange}`, {
      replace: true,
    });
    setMenuAnchor(null);
  };

  if (error) {
    return (
      <Notification
        {...{
          open: openErrorAlert,
          onClose: handleErrorAlertClose,
          type: 'error',
        }}
      >
        {error.message}
      </Notification>
    );
  }

  const menuItems = [
    {
      id: changePassword,
      label: (
        <>
          <ListItemIcon>
            <ChangePasswordIcon />
          </ListItemIcon>
          <ListItemText>{changePassword}</ListItemText>
        </>
      ),
      disabled: loading,
      onClick: handleChangePassword,
    },
    {
      id: logout,
      label: (
        <>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{logout}</ListItemText>
        </>
      ),
      disabled: loading,
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <img src="./avatar.svg" alt="User avatar" css={AvatarIcon} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(menuAnchor)}
        onClose={handleClose}
      >
        <MenuItem disabled>
          <ListItemIcon>
            <img src="./avatar.svg" alt="User avatar" css={AvatarIcon} />
          </ListItemIcon>
          <ListItemText css={MediumTextStyle}>
            {userDetails?.username}
          </ListItemText>
        </MenuItem>
        <hr css={Divider} />
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            disabled={item.disabled}
            onClick={item.onClick}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
