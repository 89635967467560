import { NewsType } from '../../../__generated__/graphql';

export interface NewsModalProps extends NewsType {
  open: boolean;
  onClose: () => void;
}

export interface LikeDislikeObj {
  like?: number;
  dislike?: number;
}

export enum LikeDislikeActionTypes {
  UpdateLikeCount,
  UpdateDislikeCount,
}

export interface LikeDislikeAction
  extends Pick<LikeDislikeObj, 'like' | 'dislike'> {
  type: LikeDislikeActionTypes;
}

export interface LikeDislikeContextProps {
  likeDislikeObj: LikeDislikeObj;
  likeDislikeDispatch: (action: LikeDislikeAction) => void;
}

export type NewsActionsToolbarProps = Pick<NewsType, '_id'>;
