import { css } from '@emotion/react';
import { SmallTextStyle } from '../../../../typography';

export const TermsAndConditionsContainer = css`
  display: flex;
  flex-direction: column;
  gap: var(--yt-size-ml);
  width: 100%;
  ${SmallTextStyle};
`;

export const ListStyle = css`
  padding-left: var(--yt-size-2xl);
  padding-right: var(--yt-size-2xl);
  white-space: break-spaces;
  overflow: hidden;
  max-width: 95%;
  & > li {
    padding: var(--yt-size-m);
  }
`;

export const SaveBtn = css({
  borderRadius: 'var(--yt-size-m)',
  padding: 'var(--yt-size-ml)',
  marginTop: 'var(--yt-size-l)',
  marginBottom: 'var(--yt-size-l)',
});

export const AcceptCheckbox = css`
  margin-left: 0;
  padding-left: var(--yt-size-m);
`;

export const LoadingSpinner = css`
  color: var(--yt-neutral);
`;

export const ErrorContainer = css`
  color: var(--yt-red);
`;
