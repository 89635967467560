/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation LikeNewsAndTrends($input: LikeNewsInput) {\n    likeNews(input: $input) {\n      likeCount\n    }\n  }\n": types.LikeNewsAndTrendsDocument,
    "\n  mutation DislikeNewsAndTrends($input: DislikeNewsInput) {\n    dislikeNews(input: $input) {\n      dislikeCount\n      _id\n    }\n  }\n": types.DislikeNewsAndTrendsDocument,
    "\n  mutation LoginUser($input: LoginInput) {\n    loginUser(input: $input) {\n      access\n      user {\n        username\n        email\n      }\n    }\n  }\n": types.LoginUserDocument,
    "\n  mutation LogoutUser {\n    logoutUser {\n      message\n    }\n  }\n": types.LogoutUserDocument,
    "\n  mutation RegisterUser($input: RegisterUserInput) {\n    registerUser(input: $input) {\n      message\n    }\n  }\n": types.RegisterUserDocument,
    "\n  mutation GetAccessTokenFromRefreshToken {\n    getAccessTokenFromRefreshToken {\n      access\n      user {\n        username\n        email\n      }\n    }\n  }\n": types.GetAccessTokenFromRefreshTokenDocument,
    "\n  mutation ChangePassword($input: ChangeUserPwdInput) {\n    changeUserPwd(input: $input) {\n      message\n    }\n  }\n": types.ChangePasswordDocument,
    "\n  mutation PasswordReset($input: PasswordResetInput) {\n    passwordReset(input: $input) {\n      message\n    }\n  }\n": types.PasswordResetDocument,
    "\n  mutation PasswordResetConfirm($input: PasswordResetConfirmInput) {\n    passwordResetConfirm(input: $input) {\n      message\n    }\n  }\n": types.PasswordResetConfirmDocument,
    "\n  mutation acceptTermsAndConditions($input: AcceptTermsAndConditionsInput) {\n    acceptTermsAndConditions(input: $input) {\n      success\n    }\n  }\n": types.AcceptTermsAndConditionsDocument,
    "\n  query EarningCallSummaries {\n    earningCallSummaries {\n      authenticatedUrl\n      author\n      dislike\n      feature\n      like\n      publishedQuarter\n      publishedYear\n      scrapedAt\n      filepath\n      summary {\n        entity\n        values {\n          subEntity\n          subEntityValues\n        }\n      }\n    }\n  }\n": types.EarningCallSummariesDocument,
    "\n  query IndustryNewsAndTrends($input: IndustryNewsAndTrendsInput) {\n    industryNewsAndTrends(input: $input) {\n      _id\n      url\n      urlToImage\n      title\n      source {\n        name\n      }\n      publishedAt\n      description\n      like\n      dislike\n      relevantContextImages\n      summary\n      category\n    }\n  }\n": types.IndustryNewsAndTrendsDocument,
    "\n  query SourceDirectory($input: SourceDirectoryInput) {\n    sourceDirectory(input: $input) {\n      categoryName\n      sources {\n        sourceName\n        sourceUrl\n      }\n    }\n  }\n": types.SourceDirectoryDocument,
    "\n  query ViewEcsSummary($input: ViewEcsSummaryInput) {\n    viewEcsSummary(input: $input) {\n      base64Str\n    }\n  }\n": types.ViewEcsSummaryDocument,
    "\n  query GrowthMarketingTrends($input: GrowthMarketingInput) {\n    growthMarketingTrends(input: $input) {\n      _id\n      url\n      urlToImage\n      title\n      source {\n        name\n      }\n      publishedAt\n      description\n      like\n      dislike\n      relevantContextImages\n      summary\n      category\n    }\n  }\n": types.GrowthMarketingTrendsDocument,
    "\n  query ViewArticlesAnalysis($input: ViewArticlesAnalysisInput) {\n    viewArticlesAnalysis(input: $input) {\n      analysisXlsBase64Str\n    }\n  }\n": types.ViewArticlesAnalysisDocument,
    "\n  query checkIfUserAcceptedTerms($input: CheckIfUserAcceptedTermsInput) {\n    checkIfUserAcceptedTerms(input: $input) {\n      accepted\n    }\n  }\n": types.CheckIfUserAcceptedTermsDocument,
    "\n  query viewLatestTrendsByCategory {\n    viewLatestTrendsByCategory {\n      category\n      categoryImg\n      keyHighlights {\n        id\n        keyHighlight\n        url\n      }\n    }\n  }\n": types.ViewLatestTrendsByCategoryDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LikeNewsAndTrends($input: LikeNewsInput) {\n    likeNews(input: $input) {\n      likeCount\n    }\n  }\n"): (typeof documents)["\n  mutation LikeNewsAndTrends($input: LikeNewsInput) {\n    likeNews(input: $input) {\n      likeCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DislikeNewsAndTrends($input: DislikeNewsInput) {\n    dislikeNews(input: $input) {\n      dislikeCount\n      _id\n    }\n  }\n"): (typeof documents)["\n  mutation DislikeNewsAndTrends($input: DislikeNewsInput) {\n    dislikeNews(input: $input) {\n      dislikeCount\n      _id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LoginUser($input: LoginInput) {\n    loginUser(input: $input) {\n      access\n      user {\n        username\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation LoginUser($input: LoginInput) {\n    loginUser(input: $input) {\n      access\n      user {\n        username\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LogoutUser {\n    logoutUser {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation LogoutUser {\n    logoutUser {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RegisterUser($input: RegisterUserInput) {\n    registerUser(input: $input) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation RegisterUser($input: RegisterUserInput) {\n    registerUser(input: $input) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GetAccessTokenFromRefreshToken {\n    getAccessTokenFromRefreshToken {\n      access\n      user {\n        username\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation GetAccessTokenFromRefreshToken {\n    getAccessTokenFromRefreshToken {\n      access\n      user {\n        username\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangePassword($input: ChangeUserPwdInput) {\n    changeUserPwd(input: $input) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ChangePassword($input: ChangeUserPwdInput) {\n    changeUserPwd(input: $input) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PasswordReset($input: PasswordResetInput) {\n    passwordReset(input: $input) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation PasswordReset($input: PasswordResetInput) {\n    passwordReset(input: $input) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PasswordResetConfirm($input: PasswordResetConfirmInput) {\n    passwordResetConfirm(input: $input) {\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation PasswordResetConfirm($input: PasswordResetConfirmInput) {\n    passwordResetConfirm(input: $input) {\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation acceptTermsAndConditions($input: AcceptTermsAndConditionsInput) {\n    acceptTermsAndConditions(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation acceptTermsAndConditions($input: AcceptTermsAndConditionsInput) {\n    acceptTermsAndConditions(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EarningCallSummaries {\n    earningCallSummaries {\n      authenticatedUrl\n      author\n      dislike\n      feature\n      like\n      publishedQuarter\n      publishedYear\n      scrapedAt\n      filepath\n      summary {\n        entity\n        values {\n          subEntity\n          subEntityValues\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query EarningCallSummaries {\n    earningCallSummaries {\n      authenticatedUrl\n      author\n      dislike\n      feature\n      like\n      publishedQuarter\n      publishedYear\n      scrapedAt\n      filepath\n      summary {\n        entity\n        values {\n          subEntity\n          subEntityValues\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query IndustryNewsAndTrends($input: IndustryNewsAndTrendsInput) {\n    industryNewsAndTrends(input: $input) {\n      _id\n      url\n      urlToImage\n      title\n      source {\n        name\n      }\n      publishedAt\n      description\n      like\n      dislike\n      relevantContextImages\n      summary\n      category\n    }\n  }\n"): (typeof documents)["\n  query IndustryNewsAndTrends($input: IndustryNewsAndTrendsInput) {\n    industryNewsAndTrends(input: $input) {\n      _id\n      url\n      urlToImage\n      title\n      source {\n        name\n      }\n      publishedAt\n      description\n      like\n      dislike\n      relevantContextImages\n      summary\n      category\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SourceDirectory($input: SourceDirectoryInput) {\n    sourceDirectory(input: $input) {\n      categoryName\n      sources {\n        sourceName\n        sourceUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  query SourceDirectory($input: SourceDirectoryInput) {\n    sourceDirectory(input: $input) {\n      categoryName\n      sources {\n        sourceName\n        sourceUrl\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ViewEcsSummary($input: ViewEcsSummaryInput) {\n    viewEcsSummary(input: $input) {\n      base64Str\n    }\n  }\n"): (typeof documents)["\n  query ViewEcsSummary($input: ViewEcsSummaryInput) {\n    viewEcsSummary(input: $input) {\n      base64Str\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GrowthMarketingTrends($input: GrowthMarketingInput) {\n    growthMarketingTrends(input: $input) {\n      _id\n      url\n      urlToImage\n      title\n      source {\n        name\n      }\n      publishedAt\n      description\n      like\n      dislike\n      relevantContextImages\n      summary\n      category\n    }\n  }\n"): (typeof documents)["\n  query GrowthMarketingTrends($input: GrowthMarketingInput) {\n    growthMarketingTrends(input: $input) {\n      _id\n      url\n      urlToImage\n      title\n      source {\n        name\n      }\n      publishedAt\n      description\n      like\n      dislike\n      relevantContextImages\n      summary\n      category\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ViewArticlesAnalysis($input: ViewArticlesAnalysisInput) {\n    viewArticlesAnalysis(input: $input) {\n      analysisXlsBase64Str\n    }\n  }\n"): (typeof documents)["\n  query ViewArticlesAnalysis($input: ViewArticlesAnalysisInput) {\n    viewArticlesAnalysis(input: $input) {\n      analysisXlsBase64Str\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query checkIfUserAcceptedTerms($input: CheckIfUserAcceptedTermsInput) {\n    checkIfUserAcceptedTerms(input: $input) {\n      accepted\n    }\n  }\n"): (typeof documents)["\n  query checkIfUserAcceptedTerms($input: CheckIfUserAcceptedTermsInput) {\n    checkIfUserAcceptedTerms(input: $input) {\n      accepted\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query viewLatestTrendsByCategory {\n    viewLatestTrendsByCategory {\n      category\n      categoryImg\n      keyHighlights {\n        id\n        keyHighlight\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query viewLatestTrendsByCategory {\n    viewLatestTrendsByCategory {\n      category\n      categoryImg\n      keyHighlights {\n        id\n        keyHighlight\n        url\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;