/** @jsxImportSource @emotion/react */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { DISLIKE_TREND, LIKE_TREND } from '../../../middleware/mutations';
import { Tooltip, Button, Dislike, Like, Notification } from '../../common';
import { AppConstants } from '../../../constants';
import { ButtonThemeTypes } from '../../common/Button/types';
import { LikeDislikeActionTypes, NewsActionsToolbarProps } from './types';
import { LikeDislikeContext } from './context/LikeDislikeContext';
import { Container, LikeDislikeIconBtn, LoadingSpinner } from './styles';

const { likeNewsText, dislikeNewsText } = AppConstants;

export const NewsActionsToolbar = ({
  _id: newsOrTrendId,
}: NewsActionsToolbarProps) => {
  const {
    likeDislikeObj: { like: noOfLikes = 0, dislike: noOfDislikes = 0 },
    likeDislikeDispatch,
  } = useContext(LikeDislikeContext);

  const [openLikeNewsAndTrendsErrorAlert, setOpenLikeNewsAndTrendsErrorAlert] =
    useState<boolean>(false);
  const [
    openDislikeNewsAndTrendsErrorAlert,
    setOpenDislikeNewsAndTrendsErrorAlert,
  ] = useState<boolean>(false);
  const [
    likeNewsAndTrends,
    {
      loading: mutatingLikeTrendNewsTrends,
      error: errorLikeTrendNewsTrends,
      data: dataLikeNewsTrends,
    },
  ] = useMutation(LIKE_TREND, {
    onError: () => null,
  });

  const [
    dislikeNewsAndTrends,
    {
      loading: mutatingDislikeTrendNewsTrends,
      error: errorDislikeTrendNewsTrends,
      data: dataDislikeNewsTrends,
    },
  ] = useMutation(DISLIKE_TREND, {
    onError: () => null,
  });

  useEffect(
    function () {
      const likeCount = dataLikeNewsTrends?.likeNews?.likeCount;
      likeCount !== null &&
        likeCount !== undefined &&
        likeDislikeDispatch({
          type: LikeDislikeActionTypes.UpdateLikeCount,
          like: likeCount,
        });
    },
    [dataLikeNewsTrends]
  );

  useEffect(
    function () {
      const dislikeCount = dataDislikeNewsTrends?.dislikeNews?.dislikeCount;
      dislikeCount !== null &&
        dislikeCount !== undefined &&
        likeDislikeDispatch({
          type: LikeDislikeActionTypes.UpdateDislikeCount,
          dislike: dislikeCount,
        });
    },
    [dataDislikeNewsTrends]
  );

  useEffect(() => {
    errorLikeTrendNewsTrends?.message &&
      setOpenLikeNewsAndTrendsErrorAlert(true);
  }, [errorLikeTrendNewsTrends]);

  useEffect(() => {
    errorDislikeTrendNewsTrends?.message &&
      setOpenDislikeNewsAndTrendsErrorAlert(true);
  }, [errorDislikeTrendNewsTrends]);

  const handlelikeNewsAndTrends = useCallback(function (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    evt?.stopPropagation();
    likeNewsAndTrends({
      variables: {
        input: {
          newsOrTrendId,
        },
      },
    });
  }, []);

  const handleDislikeNewsAndTrends = useCallback(function (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    evt?.stopPropagation();
    dislikeNewsAndTrends({
      variables: {
        input: {
          newsOrTrendId,
        },
      },
    });
  }, []);

  const handlelikeNewsAndTrendsErrorAlertClose = function (
    evt: Event | React.SyntheticEvent<any, Event>
  ) {
    evt?.stopPropagation();
    setOpenLikeNewsAndTrendsErrorAlert(false);
  };

  const handleDislikeNewsAndTrendsErrorAlertClose = function (
    evt: Event | React.SyntheticEvent<any, Event>
  ) {
    evt?.stopPropagation();
    setOpenDislikeNewsAndTrendsErrorAlert(false);
  };

  return (
    <div css={Container}>
      <Tooltip
        title={likeNewsText.replace('{0}', noOfLikes?.toString())}
        placement="top"
      >
        <div css={LikeDislikeIconBtn}>
          <Button
            theme={ButtonThemeTypes.Light}
            disabled={
              mutatingLikeTrendNewsTrends || mutatingDislikeTrendNewsTrends
            }
            onClick={handlelikeNewsAndTrends}
            iconPre={
              mutatingLikeTrendNewsTrends ? (
                <CircularProgress size={15} css={LoadingSpinner} />
              ) : (
                <Like />
              )
            }
          />
        </div>
      </Tooltip>
      <Tooltip
        title={dislikeNewsText.replace('{0}', noOfDislikes.toString())}
        placement="bottom"
      >
        <div css={LikeDislikeIconBtn}>
          <Button
            theme={ButtonThemeTypes.Light}
            disabled={
              mutatingLikeTrendNewsTrends || mutatingDislikeTrendNewsTrends
            }
            onClick={handleDislikeNewsAndTrends}
            iconPre={
              mutatingDislikeTrendNewsTrends ? (
                <CircularProgress size={15} css={LoadingSpinner} />
              ) : (
                <Dislike />
              )
            }
          />
        </div>
      </Tooltip>
      {errorLikeTrendNewsTrends && (
        <Notification
          {...{
            open: openLikeNewsAndTrendsErrorAlert,
            onClose: handlelikeNewsAndTrendsErrorAlertClose,
            type: 'error',
          }}
        >
          {errorLikeTrendNewsTrends.message}
        </Notification>
      )}
      {errorDislikeTrendNewsTrends && (
        <Notification
          {...{
            open: openDislikeNewsAndTrendsErrorAlert,
            onClose: handleDislikeNewsAndTrendsErrorAlertClose,
            type: 'error',
          }}
        >
          {errorDislikeTrendNewsTrends.message}
        </Notification>
      )}
    </div>
  );
};
