import { css } from '@emotion/react';
import { mq } from './breakpoints';

export const VendorHeaderStyle = css`
  font-family: Open Sans;
  font-size: var(--yt-size-2xl);
  ${mq[1]} {
    font-size: var(--yt-size-5xl);
  }
  font-style: normal;
  font-weight: 700;
  line-height: var(--yt-size-2xl);
`;

export const HeadingStyle = css({
  fontFamily: 'Open Sans',
  fontSize: 'var(--yt-size-2xl)',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'var(--yt-size-2xl)',
});

export const LargeTextStyle = css({
  fontFamily: 'Open Sans',
  fontSize: 'var(--yt-size-3xl)',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'var(--yt-size-2xl)',
});

export const MediumTextStyle = css({
  fontFamily: 'Open Sans',
  fontSize: 'calc(var(--yt-size-l) + 2 * var(--yt-size-s))',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'var(--yt-size-2xl)',
});

export const SmallTextStyle = css({
  fontFamily: 'Open Sans',
  fontSize: 'calc(var(--yt-size-l) + 2 * var(--yt-size-s))',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: 'var(--yt-size-2xl)',
});

export const XtraSmallTextStyle = css({
  fontFamily: 'Open Sans',
  fontSize: 'var(--yt-size-l)',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'var(--yt-size-2xl)',
});
