import { css } from '@emotion/react';
import { mq } from '../../../breakpoints';
import { XtraSmallTextStyle } from '../../../typography';

export const ViewMoreBtnContainer = (isLastItem: boolean) => css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: var(--yt-size-xl);
  ${!isLastItem &&
  `border-bottom: var(--yt-size-s) solid var(--yt-neutral-grey-4);`}
  ${mq[2]} {
    display: flex;
    grid-column: span 3 / span 3;
    gap: var(--yt-size-l);
  }
`;

export const ViewMoreBtn = css`
  border-radius: var(--yt-size-3xl);
  padding: var(--yt-size-ml);
  path {
    fill: var(--yt-dark);
  }
`;

export const LatestTrendsContainer = css`
  display: grid;
  margin: var(--yt-size-xl);
  ${mq[0]} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  ${mq[1]} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${mq[2]} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  gap: var(--yt-size-10xl);
`;

export const ReadMoreLink = css`
  ${XtraSmallTextStyle};
  display: block;
  text-decoration: none;
  color: var(--yt-link);
  z-index: 2;
  position: relative;
  width: max-content;
  white-space: nowrap;
`;

export const HighlightItem = css`
  margin: var(--yt-size-l);
  list-style-type: disc;
`;

export const HighlightItemContainer = css`
  display: flex;
  column-gap: var(--yt-size-l);
  white-space: break-words;
  justify-content: space-between;
  align-items: itemx-start;
`;

export const HighlightsList = css`
  padding: var(--yt-size-l);
`;

export const CategoryCard = css`
  width: 100%;
  min-height: var(--yt-size-50xl);
  height: 100%;
`;

export const CategoryCardTitle = css({
  marginTop: 'var(--yt-size-ml)',
  padding: 'var(--yt-size-ml)',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 'var(--yt-size-ml)',
});

export const LoaderContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CardImgSkeleton = css({
  height: 'calc(0.7 * var(--yt-size-30xl))',
});

export const SkeletonCardTitleContainer = css({
  marginTop: 'var(--yt-size-m)',
  padding: 'var(--yt-size-m) var(--yt-size-m) 0 var(--yt-size-m)',
});

export const SkeletonCardTitle = css({
  height: 'var(--yt-size-3xl)',
});

export const SkeletonCardSubtitleContainer = css({
  padding: 'var(--yt-size-s) var(--yt-size-m) 0 var(--yt-size-m)',
});

export const SkeletonCardSubtitle = css({
  height: 'var(--yt-size-3xl)',
  width: '40%',
});

export const SkeletonCardBaseStyle = css`
  height: calc(1.5 * var(--yt-size-30xl));
  width: 90%;
`;

export const SkeletonCardFooterContainer = css({
  position: 'relative',
  marginBottom: 'var(--yt-size-xl)',
});

export const CategoryCardImage = css`
  border-radius: var(--yt-size-ml);
  min-height: var(--yt-size-30xl);
`;

export const NoResultFoundStyle = css`
  ${mq[0]} {
    grid-column: span 1 / span 1;
  }
  ${mq[1]} {
    grid-column: span 2 / span 2;
  }
`;
