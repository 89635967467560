/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Fade, Skeleton } from '@mui/material';
import { AppConstants } from '../../../constants';
import { GET_SOURCES } from '../../../middleware/queries';
import { ExternalLink, Notification } from '../../common';
import { SourceDirectoryType } from '../../../__generated__/graphql';
import { MediumTextStyle } from '../../../typography';
import {
  CategoryNameLoading,
  SourceDirectoryContainer,
  SourceItem,
  SourceName,
  SourceUrl,
  SourcesListLoading,
  TitleContainer,
} from './styles';

const {
  directoryTab: {
    sections: {
      directory: { maxNoOfCategoriesLoading, insightsForGrowthDirectory },
    },
  },
} = AppConstants;

export const GrowthMarketingDirectory = () => {
  const { error, data, loading } = useQuery(GET_SOURCES, {
    variables: {
      input: {
        feature: 'growth_marketing',
      },
    },
  });
  const noOfCategoriesLoading = Math.trunc(
    maxNoOfCategoriesLoading * Math.random() + 3
  );
  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false);

  useEffect(() => {
    error?.message && setOpenErrorAlert(true);
  }, [error]);

  const handleErrorAlertClose = function () {
    setOpenErrorAlert(false);
  };

  if (error) {
    return (
      <Notification
        {...{
          open: openErrorAlert,
          onClose: handleErrorAlertClose,
          type: 'error',
        }}
      >
        {error.message}
      </Notification>
    );
  }

  const sourceDirectory =
    (data?.sourceDirectory as SourceDirectoryType[]) || [];

  return (
    <>
      <div css={[MediumTextStyle, TitleContainer]}>
        {insightsForGrowthDirectory}
      </div>
      <div css={SourceDirectoryContainer}>
        {loading ? (
          <>
            {Array.from({ length: noOfCategoriesLoading }).map((_, i) => (
              <div key={i}>
                <h5 css={CategoryNameLoading}>
                  <Skeleton
                    {...{
                      animation: 'wave',
                      variant: 'text',
                    }}
                  />
                </h5>
                <ul css={SourcesListLoading}>
                  {Array.from({
                    length: Math.trunc(
                      noOfCategoriesLoading * Math.random() + 3
                    ),
                  }).map((_, i) => (
                    <li key={i}>
                      <Skeleton
                        {...{
                          animation: 'wave',
                          variant: 'text',
                          css: SourceItem,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </>
        ) : (
          sourceDirectory?.map(
            ({ categoryName, sources }: SourceDirectoryType) => (
              <Fade key={categoryName} in timeout={500}>
                <div key={categoryName}>
                  <h5 css={SourceName}>{categoryName}</h5>
                  <ol>
                    {sources?.map((source) => (
                      <li key={source?.sourceName}>
                        <ExternalLink
                          href={source?.sourceUrl}
                          target="_blank"
                          rel="noreferrer"
                          css={SourceUrl}
                        >
                          {source?.sourceName}
                        </ExternalLink>
                      </li>
                    ))}
                  </ol>
                </div>
              </Fade>
            )
          )
        )}
      </div>
    </>
  );
};
