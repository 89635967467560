/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { ClassNames } from '@emotion/react';
import { ArrowLeft, Button, Notification } from '../common';
import { ButtonVariantTypes } from '../common/Button/types';
import { AppConstants } from '../../constants';
import { CHANGE_PASSWORD } from '../../middleware/mutations';
import { ChangePwdData } from './types';
import {
  FormContainer,
  GoBackBtn,
  SignInOrRegisterBtn,
  SignInOrRegisterProgress,
} from './styles';

const {
  passwordChangePage,
  insightsLandingTabUrls: [latestTrends],
} = AppConstants;

const {
  header,
  confirmPwdNotSameAsPwd,
  newPwdSameAsOldPwd,
  passwordChangeBtnText,
  passwordChangeLoadingText,
  goBack,
} = passwordChangePage;

const PasswordChange = () => {
  const navigate = useNavigate();

  const [
    openPwdChangeSuccessNotification,
    setOpenPwdChangeSuccessNotification,
  ] = useState<boolean>(false);
  const [passwordChangeData, setPasswordChangeData] = useState<ChangePwdData>({
    confirmNewPassword: '',
    newPassword: '',
    oldPassword: '',
  });

  const [doChangePwd, { loading, error, data: changePwdData }] = useMutation(
    CHANGE_PASSWORD,
    {
      onError: () => null,
      onCompleted: () => {
        setPasswordChangeData({
          confirmNewPassword: '',
          newPassword: '',
          oldPassword: '',
        });
        setOpenPwdChangeSuccessNotification(true);
      },
    }
  );

  const handleFieldValueChange = function (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof ChangePwdData
  ) {
    setPasswordChangeData((currentDta) => ({
      ...currentDta,
      [`${fieldName}`]: event.target.value,
    }));
  };

  const handleGoBack = function () {
    navigate(`/${latestTrends}`, {
      replace: true,
    });
  };

  const handleCloseSuccessNotification = function () {
    setOpenPwdChangeSuccessNotification(false);
  };

  const handleSubmit = async function (
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    if (
      passwordChangeData.newPassword !==
        passwordChangeData.confirmNewPassword ||
      passwordChangeData.newPassword === passwordChangeData.oldPassword
    ) {
      return;
    }
    doChangePwd({
      variables: {
        input: {
          confirmNewPassword: passwordChangeData.confirmNewPassword,
          newPassword: passwordChangeData.newPassword,
          oldPassword: passwordChangeData.oldPassword,
        },
      },
    });
  };

  const pwdNotEqualsCnfPwdText =
    passwordChangeData.confirmNewPassword !== passwordChangeData.newPassword &&
    confirmPwdNotSameAsPwd;

  const pwdEqualsOldPwd =
    passwordChangeData.oldPassword &&
    passwordChangeData.newPassword &&
    passwordChangeData.oldPassword === passwordChangeData.newPassword &&
    newPwdSameAsOldPwd;

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <div css={FormContainer}>
            <h2>{header}</h2>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                value={passwordChangeData.oldPassword}
                margin="normal"
                required
                fullWidth
                name="oldPassword"
                label="Old password"
                type="password"
                id="oldPassword"
                autoComplete="current-password"
                color="error"
                disabled={loading}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleFieldValueChange(event, 'oldPassword')}
              />
              <TextField
                value={passwordChangeData.newPassword}
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New password"
                type="password"
                id="newPassword"
                autoComplete="current-password"
                color="error"
                helperText={pwdEqualsOldPwd}
                error={!!pwdEqualsOldPwd}
                disabled={loading}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleFieldValueChange(event, 'newPassword')}
              />
              <TextField
                value={passwordChangeData.confirmNewPassword}
                margin="normal"
                required
                fullWidth
                name="confirmNewPassword"
                label="Confirm new password"
                type="password"
                id="confirmNewPassword"
                autoComplete="current-password"
                color="error"
                helperText={pwdNotEqualsCnfPwdText}
                error={!!pwdNotEqualsCnfPwdText}
                disabled={loading}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleFieldValueChange(event, 'confirmNewPassword')}
              />
              <Button
                type="submit"
                variant={ButtonVariantTypes.Primary}
                iconPre={
                  loading && (
                    <CircularProgress
                      size={15}
                      css={SignInOrRegisterProgress}
                    />
                  )
                }
                disabled={loading}
                className={css`
                  ${SignInOrRegisterBtn}
                `}
              >
                {loading ? passwordChangeLoadingText : passwordChangeBtnText}
              </Button>
              <Button
                type="button"
                variant={ButtonVariantTypes.Secondary}
                disabled={loading}
                iconPre={<ArrowLeft />}
                className={css`
                  ${GoBackBtn}
                `}
                onClick={handleGoBack}
              >
                {goBack}
              </Button>
            </Box>
          </div>
          {!!error?.message && (
            <Notification
              {...{
                open: !!error.message,
                type: 'error',
              }}
            >
              {error.message}
            </Notification>
          )}
          <Notification
            {...{
              open: openPwdChangeSuccessNotification,
              onClose: handleCloseSuccessNotification,
              type: 'success',
            }}
          >
            {changePwdData?.changeUserPwd?.message}
          </Notification>
        </>
      )}
    </ClassNames>
  );
};
export default PasswordChange;
